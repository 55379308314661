
import { FormattedMessage } from 'react-intl';

import ReleaseNotesImage from './ReleaseNotesImage';

const releaseNotes = {

    /*
     * '4.0.0': {
     * date: '2021-07-08T00:00:00',
     * content: [
     * () => (<div key={'releaseNotes.3.9.0'}>
     * <div>
     * <FormattedMessage
     * id={'releaseNotes.3.9.0'}
     * defaultMessage={
     * 'Add release notes text'
     * }
     * />
     * </div>
     * <ReleaseNotesImage image={'imageName.png'} alt={'ImageName'}/>
     * </div>),
     * ],
     *},
     */
    '4.1.0': {
        date: '2023-10-17T00:00:00',
        content: [
            () => (<div key={'releaseNotes.4.1.0'}>
                <div className={'margin-y-5'}><FormattedMessage
                    id={'releaseNotes.4.1.0'}
                    values={{
                        doublebreak: <><br/><br/></>,
                        break: <br/>,
                    }} />
                </div>
            </div>),
        ],
    },
    '4.0.0': {
        date: '2022-07-11T00:00:00',
        content: [
            () => (<div key={'releaseNotes.4.0.0'}>
                <div>
                    <FormattedMessage
                        id={'releaseNotes.4.0.0'}
                        defaultMessage={
                            'We released a new Remote Download Application with ' +
                            'version number 4.2.0. You can download the improved version ' +
                            'in the Guidance tab. To avoid system errors, please deinstall ' +
                            'and remove your current version first before installing the ' +
                            'new version. Installing the new version is essential as we will ' +
                            'soon no longer support old Remote Download Applications.'
                        }
                    />
                </div>
            </div>),
        ],
    },
    '3.9.0': {
        date: '2022-01-31T00:00:00',
        content: [
            () => (<div key={'releaseNotes.3.9.0'}>
                <div>
                    <FormattedMessage
                        id={'releaseNotes.3.9.0'}
                        defaultMessage={
                            'From now on you have the option to select groups of vehicles ' +
                            'to request remote downloads manually.'
                        }
                    />
                </div>
                <ReleaseNotesImage image={'manualDownloadStep2.png'} alt={'Manual Download Groups'}/>
            </div>),
        ],
    },
    '3.8.0': {
        date: '2021-10-28T00:00:00',
        content: [
            () => (<div key={'releaseNotes.3.8.0'}>
                <div>
                    <FormattedMessage
                        id={'releaseNotes.3.8.0'}
                        defaultMessage={
                            'We released the Company Cards page. Here you can manage your company ' +
                            'cards and assign them to vehicles. This functionality is intended for customers ' +
                            'who need to manage company cards from different companies. If you assign vehicles ' +
                            'to specific company cards, please install first the ' +
                            'Remote Download Application 4.1.4 or higher.'
                        }
                    />
                </div>
                <ReleaseNotesImage image={'manageCardsStep1.png'} alt={'Company Cards'}/>
            </div>),
        ],
    },
    '3.7.0': {
        date: '2021-08-12T00:00:00',
        content: [
            () => (<div key={'releaseNotes.3.7.0'}>
                <div>
                    <FormattedMessage
                        id={'releaseNotes.notifications'}
                        defaultMessage={
                            'From now on we will inform you via notifications about important ' +
                            'events in the service Compliant. ' +
                            'You can activate and deactivate notifications in the settings.'
                        }
                    />
                </div>
                <ReleaseNotesImage image={'notifications.png'} alt={'Notifications'}/>
            </div>),
        ],
    },
    '3.6.0': {
        date: '2021-07-08T00:00:00',
        content: [
            () => (<div key={'releaseNotes.3.6.0'}>
                <div>
                    <FormattedMessage
                        id={'releaseNotes.3.6.0'}
                        defaultMessage={
                            'We released a new Remote Download Application with ' +
                            'version number 4.1.3. You can download the improved version ' +
                            'in the Guidance tab. To avoid system errors, please deinstall ' +
                            'and remove your current version first before installing the ' +
                            'new version. Installing the new version is essential as we will ' +
                            'soon no longer support old Remote Download Applications.'
                        }
                    />
                </div>
            </div>),
        ],
    },
    '3.5.0': {
        date: '2021-07-01T00:00:00',
        content: [
            () => (<div key={'releaseNotes.3.5.0'}>
                <div>
                    <FormattedMessage
                        id={'releaseNotes.3.5.0'}
                        defaultMessage={
                            'In the Settings you can now choose whether the speed ' +
                            'data block should be included as well when downloading ' +
                            'tachograph files. Please note that the file ' +
                            'size will increase and remote downloads will take longer when downloading speed data.'
                        }
                    />
                </div>
                <ReleaseNotesImage image={'speedDataPic.png'} alt={'Speed Data Toggle Button'}/>
            </div>),
        ],
    },
    '3.4.0': {
        date: '2021-06-16T00:00:00',
        content: [
            () => (<div key={'releaseNotes.3.4.0'}>
                <FormattedMessage
                    id={'releaseNotes.3.4.0'}
                    defaultMessage={
                        'We added a search field to the archive. ' +
                        'Now you can find files from deleted vehicles more easily in the archive ' +
                        'by searching via vehicle identification number.'
                    }
                />
            </div>),
        ],
    },
    '3.3.0': {
        date: '2021-03-25T00:00:00',
        content: [
            () => (<div key={'releaseNotes.3.3.0'}>
                <FormattedMessage
                    id={'releaseNotes.3.3.0'}
                    defaultMessage={
                        '{tachographServices} is being split.'
                    }
                    values={{
                        tachographServices: 'Tachograph Services',
                        timed: 'Timed',
                        compliant: 'Compliant',
                    }}
                />
            </div>),
        ],
    },
    '3.2.0': {
        date: '2020-11-17T00:00:00',
        content: [
            () => (<div key={'releaseNotes.3.2.0'}>
                <div>
                    <FormattedMessage
                        id={'releaseNotes.3.2.0'}
                        defaultMessage={
                            'There now is a new widget in fleet monitor where You can see the latest download ' +
                            'when You select a vehicle.'
                        }
                    />
                </div>
                <ReleaseNotesImage image={'tachoFleetMonitorWidget.png'} alt={'Fleet Monitor Widget'}/>
            </div>),
        ],
    },
    '3.1.0': {
        date: '2020-03-10T00:00:00',
        content: [
            () => (<div key={'releaseNotes.3.1.0'}>
                <div>
                    <FormattedMessage
                        id={'releaseNotes.3.1.0'}
                        defaultMessage={
                            'In the archive you can now see the deletion date of each file. ' +
                            'The date indicates when the file will be deleted from the cloud' +
                            'In addition you can now set the cloud storage period of all files ' +
                            'in the settings. Ensure that the cloud storage period is in accordance ' +
                            'with the applicable laws and regulations of your country.'
                        }
                    />
                </div>
                <ReleaseNotesImage image={'storagePeriodSettings.png'} alt={'Download Intervals'}/>
            </div>),
        ],
    },
    '3.0.0': {
        date: '2020-01-22T00:00:00',
        content: [
            () => (<div key={'releaseNotes.3.0.0'}>
                <div>
                    <FormattedMessage
                        id={'releaseNotes.3.0.0'}
                        defaultMessage={
                            'We splitted Real Time and Remote Download. They now exist as two separate pages.'
                        }
                    />
                </div>
            </div>),
        ],
    },
    '2.8.0': {
        date: '2019-09-27T00:00:00',
        content: [
            toggleGlossaryDialog => (<div key={'releaseNotes.2.8.0'}>
                <div>
                    <FormattedMessage
                        id={'releaseNotes.2.8.0'}
                        defaultMessage={
                            'With this new feature you can configure the remote download intervals for tachograph ' +
                            'files and driver card files yourself. You can adjust the default download intervals for ' +
                            'all vehicles and drivers or you can set customized download intervals for individual ' +
                            'vehicles and drivers.'
                        }
                    />
                </div>
                <div>
                    <button
                        className={'btn btn-primary btn-link'}
                        onClick={() => toggleGlossaryDialog('compliant.defaultDownloadIntervals')}
                    >
                        <span className={'rioglyph rioglyph-info-sign'}/>
                        <FormattedMessage
                            id={'moreDetailsOnHelpPage'}
                            defaultMessage={'More details on our Help page'}
                        />
                    </button>
                </div>
                <ReleaseNotesImage image={'defaultDownloadIntervalsStep2.png'} alt={'Download Intervals'}/>
            </div>),
        ],
    },
    '2.7.0': {
        date: '2019-09-17T00:00:00',
        content: [
            () => (<div key={'releaseNotes.2.7.0'}>
                <FormattedMessage
                    id={'releaseNotes.2.7.0'}
                    defaultMessage={
                        'In the Archive you can now filter for files that are not yet exported to your computer. ' +
                        'Please note that this feature only works from now on. Files that you have already exported ' +
                        'in the past must be exported once again so that they are also displayed as exported.'
                    }
                />
            </div>),
        ],
    },
    '2.6.0': {
        date: '2019-07-25T00:00:00',
        content: [
            () => (<div key={'releaseNotes.2.6.0'}>
                <FormattedMessage
                    id={'releaseNotes.2.6.0'}
                    defaultMessage={
                        'With the new archive filter, you can define yourself which files should be displayed.'
                    }
                />
                <ReleaseNotesImage image={'DisplayOptionsArchive.png'} alt={'Display Options Archive'}/>
            </div>),
        ],
    },
    '2.4.0': {
        date: '2019-06-17T00:00:00',
        content: [
            () => (<div key={'releaseNotes.2.4.0'}>
                <FormattedMessage
                    id={'releaseNotes.2.4.0'}
                    defaultMessage={
                        'With the new dialogue for table settings, you can define yourself ' +
                        'which table columns should be displayed in which order.'
                    }
                />
                <ReleaseNotesImage image={'release_manual_download.jpg'} alt={'Compliant Manual Download'}/>
            </div>),
        ],
    },
    '2.3.0': {
        date: '2019-06-05T00:00:00',
        content: [
            () => (<div key={'releaseNotes.2.3.0'}>
                <FormattedMessage
                    id={'releaseNotes.2.3.0'}
                    defaultMessage={
                        'Do you use Compliant and DAKO TachoWeb ?? From now on we can automatically forward your ' +
                        'tachograph and driver card files to DAKO TachoWeb. In DAKO TachoWeb you can analyze your ' +
                        'files for infringements as usual. Just book DAKO TachoWeb Connect in the Marketplace.'
                    }
                />
            </div>),
        ],
    },
    '2.0.0': {
        date: '2019-03-04T00:00:00',
        content: [
            () => (<div key={'releaseNotes.2.0.0'}>
                <FormattedMessage
                    id={'releaseNotes.2.0.0'}
                    defaultMessage={
                        'From now on you can book our new Tachograph Service called Timed in the Marketplace ' +
                        'for your vehicles. With Timed you get an overview of the driving and rest times of ' +
                        'your drivers in real time.'
                    }
                />
            </div>),
        ],
    },
    '1.8.0': {
        date: '2019-02-20T00:00:00',
        content: [
            () => (<div key={'releaseNotes.1.8.0'}>
                <FormattedMessage
                    id={'releaseNotes.1.8.0'}
                    defaultMessage={
                        'With the new Overview page you can check when the last remote download ' +
                        'was performed and when the next one is due.'
                    }
                />
            </div>),
            () => (<div key={'releaseNotes.1.3.0'}>
                <FormattedMessage
                    id={'releaseNotes.1.3.0'}
                    defaultMessage={
                        'We improved the archive for you. Now you have a quicker overview of your saved files ' +
                        'and can export them more easily.'
                    }
                />
            </div>),
        ],
    },
    '1.7.0': {
        date: '2019-02-04T00:00:00',
        content: [
            () => (<div key={'releaseNotes.1.7.0'}>
                <FormattedMessage
                    id={'releaseNotes.1.7.0'}
                    defaultMessage={
                        'We have improved the application Tachograph Services for you. The new welcome page will ' +
                        'help you get started and announce the soon to be available service called Timed.'
                    }
                />
            </div>),
        ],
    },
    '1.6.0': {
        date: '2018-11-19T00:00:00',
        content: [
            () => (<div key={'releaseNotes.1.6.0'}>
                <FormattedMessage
                    id={'releaseNotes.1.6.0'}
                    defaultMessage={
                        'Tachograph Services is now available in 20 languages.'
                    }
                />
            </div>),
        ],
    },
    '1.5.0': {
        date: '2019-09-18T00:00:00',
        content: [
            () => (<div key={'releaseNotes.1.5.0'}>
                <FormattedMessage
                    id={'releaseNotes.1.5.0'}
                    defaultMessage={
                        'Do you use Compliant and VDO TIS-Web® ?? From now on we can automatically forward ' +
                        'your tachograph and driver card files to VDO TIS-Web® Data Management. In the VDO ' +
                        'TIS-Web® Data Management you can analyze your files for infringements as usual. Just ' +
                        'book VDO TIS-Web® Connect in the Marketplace.'
                    }
                />
            </div>),
        ],
    },
    '1.4.0': {
        date: '2018-09-04T00:00:00',
        content: [
            () => (<div key={'releaseNotes.1.4.0'}>
                <FormattedMessage
                    id={'releaseNotes.1.4.0'}
                    defaultMessage={
                        `Tachograph Services is now also available in Portuguese, Slovakian and Lithuanian.`
                    }
                />
            </div>),
        ],
    },
    '1.3.0': {
        date: '2018-07-23T00:00:00',
        content: [
            () => (<div key={'releaseNotes.1.3.0'}>
                <FormattedMessage
                    id={'releaseNotes.1.3.0'}
                    defaultMessage={
                        'We improved the archive for you. Now you have a quicker overview of your saved files ' +
                        'and can export them more easily.'
                    }
                />
            </div>),
        ],
    },
    '1.2.0': {
        date: '2018-06-27T00:00:00',
        content: [
            () => (<div key={'releaseNotes.1.2.0'}>
                <FormattedMessage
                    id={'releaseNotes.1.2.0'}
                    defaultMessage={
                        'We are working continuously on extending and improving Tachograph Services. ' +
                        'From now on this dialogue  will inform you about everything that`s new in the application.'
                    }
                />
            </div>),
        ],
    },
};

export default releaseNotes;
