export const sandboxConfig = {
    homeRoute: 'https://remotedownload-sandbox.rio.cloud',
    backend: {
        APPS_JSON: 'https://remotedownload-sandbox.rio.cloud/dist/apps.json',
        LOCATION_SERVICE: 'https://api.iam.ccp-dev.net/frondend-mock/1.0.0',
        NOTIFICATION_SERVICE: 'https://api.iam.ccp-dev.net/frondend-mock/1.0.0',
        TACHOGRAPH_SERVICES_SERVICE: 'https:///api.remotedownload-sandbox.rio.cloud',
        RETENTION_PATH: `https:///api.remotedownload-sandbox.rio.cloud/dataremover/retention-periods/fleet`,
        TACHO_SETTINGS_PATH: `https:///api.remotedownload-sandbox.rio.cloud/backendmock/tachograph-usersettings/settings`,
        VEHICLES_WITH_SUBSCRIPTION_PATH: `https:///api.remotedownload-sandbox.rio.cloud/downloadscheduler/vehicles-with-subscription`,
        ANALYSIS_PORTAL: 'https://man.pre.tachoweb.eu'
    },
    login: {
        authority: `https://api.remotedownload-sandbox.rio.cloud/backendmock/`,
        mockAuthorization: true,
        mockLocale: 'de-DE',
        clientId: '2qLovYAssnTf0tdZYFfeCN7QcGQa',
        callbackUri: 'https://remotedownload-sandbox.rio.cloud/redirectlogin',
        redirectUri: 'https://remotedownload-sandbox.rio.cloud/redirect.html',
        silentRedirectUri: 'https://remotedownload-sandbox.rio.cloud/redirect.html'
    },
};
