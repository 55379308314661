import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import ToggleButton from '@rio-cloud/rio-uikit/ToggleButton';
import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import TableSettingsDialog from '@rio-cloud/rio-uikit/TableSettingsDialog';
import cloneDeep from 'lodash/fp/cloneDeep';
import get from 'lodash/get';

import {
    formatVehicleName,
    formatDriver,
} from '../../components/ArchiveTable/FormatUtils';

import {
    DRIVERS_MAX_INTERVAL,
    DRIVERS_MIN_INTERVAL,
    DRIVERS_STAGING_MIN_INTERVAL,
    LOADINGDATA,
    VEHICLES_MAX_INTERVAL,
    VEHICLES_MIN_INTERVAL,
    VEHICLES_STAGING_MIN_INTERVAL,
} from '../../constants/archive';
import formatAsSpan from '../../utils/formatAsSpan';

import EditDownloadIntervalDialog
    from '../Settings/IntervalSettings/EditDownloadIntervalDialog';

import WidgetWrapper from '../../components/ArchiveTable/Widgets/WidgetWrapper';

import store from '../../setup/store';

import CompliantWidget from '../../components/common/widgets/CompliantWidget';

import getNoOfSubscribedVehicles from '../../utils/getNoOfSubscribedVehicles';

import {shouldEnableRDA} from '../../utils/shouldEnableRDA';

import {GetTranslateText} from './helpers';

import OverviewTable from './OverviewTable';

const TWO_DAYS_MIN = 2;

const getCustomerMin = isDriver => {
    const shouldUseStagingInterval =
        [
            '8ef04c71-8105-4bfb-a97c-a70db9d66975',
            'ef0dd23c-6c03-45b6-acb5-3bda8441a21a',
            '7048802a-afcc-40f2-86a2-0f7535ff7a88',
            '7dd9cc30-3df3-4119-b257-d55423763146',
            '876f3819-6e1a-47cb-8e84-4d94f5055ee5',
            '506d0f4f-a388-4f82-84aa-45a9b3ec5547',
            '08d42fef-088b-46da-8604-b85852276f80',
            '98f60866-1bc5-4a6b-8ab1-e49bb8d8c104',
            '816abe30-6113-4b04-91ff-d7b3998dd788',
            '0b375795-08db-4d1a-a0dc-fd30906d8796',
            'ed0a98f6-bcf5-4be3-aaaf-976d90918660',
            '237c58e3-60b5-4442-a9ec-8641ea39325b',
            'b92a52c4-6663-4fc9-8097-671a025ca4c2',
            '69967c01-4c22-4667-ab9a-9bbc4c74c3e7',
            '4413e861-ddc2-4223-b7d8-868cc35c73ca',
            '23639feb-ca5e-4b9a-9e16-5e9cf92f07e2',
            'ccf10c95-b240-4570-b652-9f4e086566ae',
            '0eaa962f-ddf9-4280-9d37-3d0760959493',
            '9eb3a6f8-a793-4bba-a6c0-792a45c8197f',
            '364f4524-bb0f-45ce-8db7-e973925c011e',
            '4738f7b8-6f9a-47d3-939b-22a69302c949',
            'cc67c225-735d-45c2-9ff0-22155936ed67',
            'a4de060d-d440-40b9-b307-ca29b866f07f',
            '02200000-0000-0000-0000-000000000022',
            // Start of analysis portal fleets
            '0ff74f0d-c891-47ca-a7e6-9501be388fe7',
            '876f3819-6e1a-47cb-8e84-4d94f5055ee5',
            '728519fc-3337-4471-adf7-d4cb3249ff0f',
            '1f5c1128-8005-4716-98b9-721ed148b8a6',
            'd80732c1-e2ce-4955-9acf-a9df5e64ff37',
            '0e3174e5-113e-43d3-aecd-36bb39dfda67',
            'b472b5f1-3645-408e-bba6-1e2e5aa51e28',
            '97576b68-0788-402f-8e73-df247947b85b',
            '0bdfd716-3509-427a-9706-5554681ed756',
            'ef0dd23c-6c03-45b6-acb5-3bda8441a21a',
            '985941b3-b0ed-4c33-b181-72b64776b04b',
            '734c0a5e-fc49-4a05-a667-0100724f46fe',
            '3c88c5e8-bb2d-434c-882a-5578a8e7523c',
            'f8a883eb-5509-4cbd-aa19-cf9118216b3c',
            'dcc760bc-c3a6-4b32-8aa7-12f8e0a9d5c6',
            '06b15a2f-063e-4bee-bf9a-8b64cfb337bc',
            '824ddaa0-79bd-4a16-b946-46622a0239df',
            'de84d351-4410-4cc9-8de8-3c82b5ba969f',
            'b4696cdf-6077-4f5e-8199-3e30a199a64c',
            'bc0a86fc-b7df-4109-9ea2-36f8cf77ff8d',
            '3cfc7da0-32b8-4ed5-b048-9dfb89062928',
            '257b73aa-e1d0-41b9-ab64-69830848a66d',
        ].includes(get(
            store.getState(),
            'login.userProfile.account',
            '',
        ));

    if (shouldUseStagingInterval) {
        return isDriver ? DRIVERS_STAGING_MIN_INTERVAL : VEHICLES_STAGING_MIN_INTERVAL;
    }

    const shouldUseOneDayDriverTwoDaysVehicle =
    [
        'caf1ff3f-f39b-4762-9e3e-0627b2931857',
        '0a223337-4d4c-4b26-becd-c0ea55684258',
        'mockaccount',
    ].includes(get(
        store.getState(),
        'login.userProfile.account',
        '',
    ));

    if (shouldUseOneDayDriverTwoDaysVehicle) {
        return isDriver ? DRIVERS_STAGING_MIN_INTERVAL : TWO_DAYS_MIN;
    }

    const shouldUseTwoDays =
        [''].includes(get(
            store.getState(),
            'login.userProfile.account',
            '',
        ));

    if (shouldUseTwoDays) {
        return DRIVERS_STAGING_MIN_INTERVAL;
    }

    return isDriver ? DRIVERS_MIN_INTERVAL : VEHICLES_MIN_INTERVAL;
};

export const getMinValues = () => ({
    driversMinInterval: getCustomerMin(true),
    vehiclesMinInterval: getCustomerMin(false),
});

export const formatWithSpinner = (cell, row) => {
    if (row.fileId === LOADINGDATA) {
        return <div className={'LoadingSpinner'}><Spinner /></div>;
    }

    return formatAsSpan(cell);
};

const columnLabels = {
    vehicle: <FormattedMessage id={'vehicle'} />,
    driver: <FormattedMessage id={'driver'}/>,
    timeCreatedUtc: <FormattedMessage id={'archive.transferDate'} />,
    driverCard: <FormattedMessage id={'driverCard'}/>,
    identification: <FormattedMessage id={'identification'} defaultMessage={'Identification'}/>,
    transferType: <FormattedMessage id={'archive.transferType'}/>,
    dueDate: <FormattedMessage id={'archive.dueDate'} defaultMessage={'Due date'}/>,
    downloadInterval: <FormattedMessage id={'administration.downloadInterval'} defaultMessage={'Download Interval'}/>,
    plannedDownloadDate: <FormattedMessage
        id={'archive.plannedDownloadDate'}
        defaultMessage={'Planned download date'}/>,
    currentLevel: <FormattedMessage
        id={'archive.subscriptionType'}
        defaultMessage={'Subscription Type'}/>,
};

const disabledVehicleColumns = ['vehicle'];
const defaultVehicleColumnOrder = [
    'vehicle', 'timeCreatedUtc', 'identification', 'transferType', 'dueDate',
    'downloadInterval', 'plannedDownloadDate', 'currentLevel',
];

const disabledDriverColumns = ['driver'];
const defaultDriverColumnOrder = [
    'driver', 'timeCreatedUtc', 'driverCard', 'transferType', 'dueDate',
    'downloadInterval', 'plannedDownloadDate',
];

class OverviewPage extends Component {
    constructor(props) {
        super(props);

        const { columnsOrder, columnsHidden } = this.initTableSettings();
        this.state = {
            defaultSortName: this.props.tableOptions.defaultSortName,
            selected: [],
            downloadIntervalData: [],
            showEditDownloadIntervalDialog: false,
            showTableSettingsDialog: false,
            columnOrder: columnsOrder,
            hiddenColumns: columnsHidden,
            vehiclesWithSubscription: store.getState().tachographservices.tree.vehiclesWithSubscription,
            rawVehicles: store.getState().tachographservices.tree.rawVehicles,
        };

        this.onSortChange = this.onSortChange.bind(this);
        this.openEditDownloadIntervalDialog = this.openEditDownloadIntervalDialog.bind(this);
        this.closeEditDownloadIntervalDialog = this.closeEditDownloadIntervalDialog.bind(this);
        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.setDownloadInterval = this.setDownloadInterval.bind(this);
        this.toggleTableSettingsDialog = this.toggleTableSettingsDialog.bind(this);
        this.handleColumnChange = this.handleColumnChange.bind(this);
        this.saveOverviewTableSettings = this.saveOverviewTableSettings.bind(this);
    }

    // eslint-disable-next-line max-statements, complexity
    initTableSettings() {
        // eslint-disable-next-line immutable/no-let
        let columnsOrder = {};
        // eslint-disable-next-line immutable/no-let
        let columnsHidden = {};
        // Driver Config
        if (this.props.isDriver) {
            if (Object.keys(this.props.overviewTable.driver).length > 0) {
                // eslint-disable-next-line prefer-destructuring
                columnsOrder = this.props.overviewTable.driver.columnsOrder;
                // eslint-disable-next-line prefer-destructuring
                columnsHidden = this.props.overviewTable.driver.hiddenColumns;
            } else {
                columnsOrder = defaultDriverColumnOrder;
                columnsHidden = [];
            }
        }

        // Vehicle Config
        if (!this.props.isDriver) {
            if (Object.keys(this.props.overviewTable.vehicle).length > 0) {
                // eslint-disable-next-line prefer-destructuring
                columnsOrder = this.props.overviewTable.vehicle.columnsOrder;
                // eslint-disable-next-line prefer-destructuring
                columnsHidden = this.props.overviewTable.vehicle.hiddenColumns;
                if (!columnsOrder.includes('currentLevel') && !columnsHidden.includes('currentLevel')) {
                    columnsOrder.push('currentLevel');
                }
            } else {
                columnsOrder = defaultVehicleColumnOrder;
                columnsHidden = [];
            }
        }
        return { columnsOrder, columnsHidden };
    }

    toggleTableSettingsDialog() {
        this.setState({ showTableSettingsDialog: !this.state.showTableSettingsDialog });
    }

    handleColumnChange(columnOrder, hiddenColumns, columnsDetails = this.state.columnsDetails) {
        this.setState({
            columnOrder,
            hiddenColumns,
            columnsDetails,
        });
    }

    renderTableSettingsDialog() {
        const { showTableSettingsDialog, columnOrder, hiddenColumns } = this.state;
        // eslint-disable-next-line no-warning-comments
        // TODO add translations
        return (
            <FormattedMessage id={'searchColumn'}>
                {placeholder => (
                    <TableSettingsDialog
                        show={showTableSettingsDialog}
                        title={<FormattedMessage id={'tableSettings'} />}
                        onHide={this.toggleTableSettingsDialog}
                        onColumnChange={this.handleColumnChange}
                        defaultColumnOrder={this.props.isDriver ? defaultDriverColumnOrder : defaultVehicleColumnOrder}
                        defaultHiddenColumns={[]}
                        columnOrder={columnOrder}
                        hiddenColumns={hiddenColumns}
                        columnLabels={columnLabels}
                        disabledColumns={this.props.isDriver ? disabledDriverColumns : disabledVehicleColumns}
                        closeButtonText={<FormattedMessage id={'close'} />}
                        resetButtonText={<FormattedMessage id={'resetToDefault'} />}
                        searchPlaceholder={placeholder}
                        notFoundMessage={GetTranslateText('noColumnsFoundForSearch')}
                        autoLabel={'Auto'}
                        applyButtonText={<FormattedMessage id={'apply'} />}
                        cancelButtonText={<FormattedMessage id={'cancel'} />}
                        onApply={this.saveOverviewTableSettings}
                    />)}
            </FormattedMessage>
        );
    }

    saveOverviewTableSettings(order, hidden) {
        const {overviewTable} = this.props;

        if (this.props.isDriver) {
            overviewTable.driver.columnsOrder = order;
            overviewTable.driver.hiddenColumns = hidden;
            this.props.setOverviewTableSettings(overviewTable);
        }

        if (!this.props.isDriver) {
            overviewTable.vehicle.columnsOrder = order;
            overviewTable.vehicle.hiddenColumns = hidden;
            this.props.setOverviewTableSettings(overviewTable);
        }
    }

    getTableViewToggles() {
        const { singleCard, multiCards } = this.props;
        return (
            <div className={'btn-group'}>
                <ToggleButton
                    key={'Overview-TableView-table-button'}
                    className={'btn-icon-only'}
                    active={!multiCards && !singleCard}
                    onClick={() => {
                        this.props.setNormalTableView();
                        this.props.setEventOnTableView();
                    }}
                >
                    <span className={'rioglyph rioglyph-table-view'}> </span>
                </ToggleButton>
                <ToggleButton
                    key={'Overview-TableView-singleCard-button'}
                    className={'btn-icon-only'}
                    active={singleCard}
                    onClick={() => {
                        this.props.setSingleCardView();
                        this.props.setEventOnTableView();
                    }}
                >
                    <span className={'rioglyph rioglyph-th-list'}> </span>
                </ToggleButton>
                <ToggleButton
                    key={'Overview-TableView-multiCards-button'}
                    className={'btn-icon-only'}
                    active={multiCards}
                    onClick={() => {
                        this.props.setMultiCardsView();
                        this.props.setEventOnTableView();
                    }}
                >
                    <span className={'rioglyph rioglyph-split-view'}> </span>
                </ToggleButton>
            </div>
        );
    }

    onSortChange(sortName, sortOrder) {
        this.setState({ defaultSortName: sortName });
        if (sortName === 'plannedDownloadDate') {
            this.props.tableOptions.onSortChange(
                sortName,
                sortOrder,
                true,
            );
        } else if (sortName === 'downloadInterval') {
            this.props.tableOptions.onSortChange(
                sortName,
                sortOrder,
                true,
            );
        } else if (sortName === 'dueDate') {
            this.props.tableOptions.onSortChange(
                sortName,
                sortOrder,
                true,
            );
        } else {
            this.props.tableOptions.onSortChange(
                'timeCreatedUtc',
                sortOrder,
                true,
            );
        }
    }

    openEditDownloadIntervalDialog() {
        if (this.props.isDownloadIntervalEnabled) {
            this.setState({
                showEditDownloadIntervalDialog: true,
            });
        }
    }

    closeEditDownloadIntervalDialog() {
        this.setState({
            showEditDownloadIntervalDialog: false,
        });
    }

    getDownloadIntervalData(selected = this.state.selected) {
        return this.props.listFilesData
            .filter(item => selected.includes(item.fileId.toString()))
            .map(item => ({
                ...item,
                asset: this.props.isDriver ?
                    formatDriver(
                        item.driver,
                        item,
                    ) :
                    formatVehicleName(
                        item.vehicle,
                        item,
                    ),
                key: item.fileId,
                category: this.props.isDriver ? 'driver' : 'truck',
            }));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.shownTableType !== this.props.shownTableType || this.props.shouldRefetchArchive) {
            this.setState({ selected: [] });
            const { columnsOrder, columnsHidden } = this.initTableSettings();
            this.setState({
                selected: [],
                columnOrder: columnsOrder,
                hiddenColumns: columnsHidden,
            });
        }
    }

    onSelectionChange(newSelected) {
        this.setState({ selected: newSelected, downloadIntervalData: this.getDownloadIntervalData(newSelected) });
    }

    setDownloadInterval(ids, interval) {
        this.props.setDownloadInterval({ ids, interval, isDriver: this.props.isDriver });
    }

    // eslint-disable-next-line complexity
    render() { // eslint-disable-line max-lines-per-function
        const {
            tableOptions,
            listFilesData,
            isLoading,
            singleCard,
            multiCards,
            isDriver,
        } = this.props;

        const options = cloneDeep(tableOptions);
        options.onSortChange = this.onSortChange;
        options.defaultSortName = this.state.defaultSortName;
        const tableProps = {
            data: listFilesData,
            options,
            singleCard,
            multiCards,
            isDriver,
            enableRowSelection: true,
            clickToSelect: true,
            selectedElements: this.state.selected,
            selectionEnabled: true,
            isOverviewSelected: true,
            columnOrder: this.state.columnOrder,
            hiddenColumns: this.state.hiddenColumns,
            onSelectionChange: this.onSelectionChange,
            additionalBatchButtonItems: this.props.isDownloadIntervalEnabled ?
                [
                    {
                        value:
                            <span>
                                <FormattedMessage
                                    id={'administration.editDownloadInterval'}
                                    defaultMessage={'Edit download interval'}
                                />
                                <span className={'margin-left-5'}>
                                    {`(${this.state.selected.length})`}
                                </span>
                            </span>,
                        onSelect: this.openEditDownloadIntervalDialog,
                        disabled: this.state.selected.length === 0,
                    },
                ] :
                [],
        };

        const { driversMinInterval, vehiclesMinInterval } = getMinValues();

        return (
            <div>
                <div className={'WidgetWrapper row'}>
                    {(getNoOfSubscribedVehicles(
                        '1',
                        this.state.vehiclesWithSubscription,
                    ) !== 0 || shouldEnableRDA()) && <div className={'col-md-6'}>
                        <CompliantWidget
                            header = {'Compliant'}
                            description= {<div>
                                <FormattedMessage
                                    id={'widget.compliantSLine1'}
                                    // eslint-disable-next-line max-len
                                    defaultMessage={'With Compliant you must use a card reader and work with the Remote Download Application to manage your company cards.'}
                                />
                                <br/>

                                <div>
                                    <div style={{marginTop: 48}}>
                                        <FormattedMessage
                                            id={'widget.compliantSLine2-1'}
                                            defaultMessage={'Upgrade now to use company card hosting instead.'}
                                        />
                                        <br/>
                                        <span style={{float: 'left'}}>
                                            <FormattedMessage
                                                id={'widget.compliantSLine3-1'}
                                                defaultMessage={'Book Compliant M to use company card hosting.'}
                                            />
                                        </span>
                                        <iframe
                                            className={'buyButton margin-left-10'}
                                            style={{width: 65, float: 'left', height: 19}}
                                            // eslint-disable-next-line max-len
                                            src={`https://buybutton.marketplace.rio.cloud/?productId=man-sku00000004&level=21&internalCampaign=upselling-Compliant&noPrice&notButtonText&textOnly&buttonLeft`}>
                                        </iframe>
                                    </div>
                                </div></div>
                            }
                            footer={<span>
                                <span className={'rioglyph rioglyph-truck margin-right-5'}/>
                                <span className={'margin-right-5'}>
                                    {getNoOfSubscribedVehicles(
                                        '1',
                                        this.state.vehiclesWithSubscription,
                                    )}/{this.state.rawVehicles.length}
                                </span>
                                <FormattedMessage
                                    id={'widget.vehiclesWithCompliantSSubscription-1'}
                                    defaultMessage={'Vehicles with Compliant subscription'}
                                />
                                <span
                                    style={{float: 'right'}}
                                    className={'rioglyph rioglyph-card-reader margin-right-5 text-size-h4'}/>
                            </span>}
                        />
                    </div>}
                    <div
                        className={`col-md-${getNoOfSubscribedVehicles(
                            '1',
                            this.state.vehiclesWithSubscription,
                        ) === 0 && !shouldEnableRDA() ?
                            '12' :
                            '6'}`}>
                        <CompliantWidget
                            header = {'Compliant M'}
                            description= {<div><div className={'text-size-14'}>
                                <FormattedMessage
                                    id={'widget.compliantMLine1'}
                                    // eslint-disable-next-line max-len
                                    defaultMessage={'Send us your company cards. We host them for you in our card hotel.'}
                                />
                                <br/>
                                <FormattedMessage
                                    id={'widget.compliantMLine2-1'}
                                    // eslint-disable-next-line max-len
                                    defaultMessage={'Benefit from our safe and secure company card hosting.'}
                                />
                            </div>
                            <div className={'text-size-14'}>
                                <ul>
                                    <li><FormattedMessage
                                        id={'widget.compliantMLine3'}
                                        defaultMessage={'Your company cards are online 24/7'}
                                    /></li>
                                    <li><FormattedMessage
                                        id={'widget.compliantMLine4-1'}
                                        defaultMessage={'Get remote downloads more often'}
                                    /></li>
                                </ul>
                            </div>
                            <iframe
                                className={'buyButton'}
                                style={{float: 'left'}}
                                // eslint-disable-next-line max-len
                                src={`https://buybutton.marketplace.rio.cloud/?productId=man-sku00000004&level=21&internalCampaign=upselling-Compliant&buttonLeft`}>
                            </iframe></div>
                            }
                            footer={<span>
                                <span className={'rioglyph rioglyph-truck margin-right-5'}/>
                                <span className={'margin-right-5'}>
                                    {getNoOfSubscribedVehicles(
                                        '2',
                                        this.state.vehiclesWithSubscription,
                                    )}/{this.state.rawVehicles.length}
                                </span>
                                <FormattedMessage
                                    id={'widget.vehiclesWithCompliantMSubscription-1'}
                                    defaultMessage={'Vehicles with Compliant M subscription'}
                                />
                                <span
                                    style={{float: 'right'}}
                                    className={'rioglyph rioglyph-card-hotel margin-right-5 text-size-h4'}/>
                            </span>}
                            newFeature = {true}
                        />
                    </div>
                </div>
                <WidgetWrapper/>
                <TableToolbar>
                    <div className={'table-toolbar-container'}>
                        <div className={'table-toolbar-group-right'}>
                            <div className={'table-toolbar-column table-toolbar-column-spacer'}>
                                <div className={'btn-toolbar'}>
                                    {this.getTableViewToggles()}
                                </div>
                            </div>
                            <div className={'table-toolbar-column'}>
                                <button
                                    className={'btn btn-default btn-icon-only'}
                                    onClick={this.toggleTableSettingsDialog}
                                    role={'button'}>
                                    <span className={'rioglyph rioglyph-cog'}></span>
                                </button>
                            </div>
                        </div>
                        {this.state.showTableSettingsDialog && this.renderTableSettingsDialog()}
                    </div>
                </TableToolbar>
                { isLoading && <Spinner/> }
                { !isLoading && <OverviewTable {...tableProps}/>}
                { this.props.isDownloadIntervalEnabled &&
                    <EditDownloadIntervalDialog
                        open={this.state.showEditDownloadIntervalDialog}
                        hideDialog={this.closeEditDownloadIntervalDialog}
                        selectedAssets={this.state.downloadIntervalData}
                        saveIntervals={this.setDownloadInterval}
                        min={this.props.isDriver ? driversMinInterval : vehiclesMinInterval}
                        max={this.props.isDriver ? DRIVERS_MAX_INTERVAL : VEHICLES_MAX_INTERVAL}
                    />
                }
            </div>
        );
    }
}

OverviewPage.propTypes = {
    listFilesData: PropTypes.array,
    shownTableType: PropTypes.string,

    isLoading: PropTypes.bool,
    singleCard: PropTypes.bool,
    multiCards: PropTypes.bool,
    setNormalTableView: PropTypes.func,
    setMultiCardsView: PropTypes.func,
    setSingleCardView: PropTypes.func,

    listFilesActivePage: PropTypes.number,
    listFilesTotalFilesCount: PropTypes.number,
    tableOptions: PropTypes.object,

    setDownloadInterval: PropTypes.func,
    isDriver: PropTypes.bool,

    isDownloadIntervalEnabled: PropTypes.bool,

    shouldRefetchArchive: PropTypes.bool,
    setEventOnTableView: PropTypes.func,
    overviewTable: PropTypes.object,
    setOverviewTableSettings: PropTypes.func,
    vehiclesWithSubscription: PropTypes.array,
};

export const mapStateToProps = state => ({
    overviewTable: get(
        state,
        'tachographservices.common.tachoSettings.overviewTable',
    ),
    vehiclesWithSubscription: get(
        state,
        'tachographservices.tree.vehiclesWithSubscription',
    ),
    rawVehicles: get(
        state,
        'tachographservices.tree.rawVehicles',
    ),
});

export default OverviewPage;
