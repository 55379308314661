import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import Slider from '@rio-cloud/rio-uikit/Slider';
import NumberInput from '@rio-cloud/rio-uikit/NumberInput';

import { RETENTION_PERIOD_MAX_MONTHS, RETENTION_PERIOD_MIN_MONTHS } from '../../../constants/administration';

import TooltipTrigger from '../../../components/common/TooltipTrigger';

import withPermissions from '../../../utils/withPermissions';

import ConfirmDownloadIntervalDialog from '../IntervalSettings/ConfirmDownloadIntervalDialog';
import { lastRowClass } from '../IntervalSettings/IntervalSettingsSidebar';

class CloudStorageSettingsSidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            retentionPeriodValue: props.retentionPeriodValue,
            confirmDialogOpen: false,
        };

        this.changeRetentionPeriodValue = this.changeRetentionPeriodValue.bind(this);
        this.closeSidebar = this.closeSidebar.bind(this);
        this.save = this.save.bind(this);
        this.closeConfirm = this.closeConfirm.bind(this);
        this.abortConfirm = this.abortConfirm.bind(this);
    }

    changeRetentionPeriodValue(value) {
        this.setState({ retentionPeriodValue: value });
    }

    closeSidebar() {
        this.props.setSettingsSidebarOpen(false);
    }

    save() {
        this.setState({
            confirmDialogOpen: true,
        });

        try {
            document.getElementsByClassName('ApplicationHeader')[0].classList.add('z-index-1');
        } catch (error) {
            console.log(error); // eslint-disable-line no-console
        }
    }

    closeConfirm() {
        this.setState({
            confirmDialogOpen: false,
        });

        try {
            document
                .getElementsByClassName('ApplicationHeader')[0]
                .classList
                .remove('z-index-1');
        } catch (error) {
            console.log(error); // eslint-disable-line no-console
        }

        if (this.retentionPeriodChanged()) {
            this.props.saveRetentionPeriod(this.state.retentionPeriodValue);
        }

        this.closeSidebar();
    }

    abortConfirm() {
        this.setState({
            confirmDialogOpen: false,
        });

        try {
            document
                .getElementsByClassName('ApplicationHeader')[0]
                .classList
                .remove('z-index-1');
        } catch (error) {
            console.log(error); // eslint-disable-line no-console
        }
    }

    getRetentionPeriodsSection() {
        return withPermissions(
            this.props.permissions,
            'retention-period.update',
            <div className={lastRowClass}>
                <div className={'display-block'}>
                    <div className={'SidebarHeader padding-0 height-25 form-group'}>
                        <FormattedMessage
                            id={'administration.cloudStoragePeriodSettings'}
                            defaultMessage={'Cloud storage period settings'}
                        />
                        <TooltipTrigger
                            width={350}
                            baseKey={'administration.cloudStoragePeriodSettingsInfoText'}
                            tooltip={<FormattedMessage id={'administration.cloudStoragePeriodSettingsInfoText'}/>}
                        >
                            <div className={'text-primary margin-left-5'}>
                                <span className={'rioglyph rioglyph-info-sign'}/>
                            </div>
                        </TooltipTrigger>
                    </div>
                </div>
                <div className={'display-block'}>
                    <div>
                        <span className={'rioglyph rioglyph-cloud margin-right-5'}/>
                        <FormattedMessage
                            id={'administration.cloudStoragePeriodForAllFiles'}
                            defaultMessage={'Cloud storage period for all files'}
                        />
                    </div>
                    <div className={'display-flex align-items-center'}>
                        <Slider
                            value={this.state.retentionPeriodValue}
                            minValue={RETENTION_PERIOD_MIN_MONTHS}
                            maxValue={RETENTION_PERIOD_MAX_MONTHS}
                            onChange={this.changeRetentionPeriodValue}
                        />
                        <div className={'margin-left-10'} style={{ minWidth: '110px' }}>
                            <NumberInput
                                min={RETENTION_PERIOD_MIN_MONTHS}
                                max={RETENTION_PERIOD_MAX_MONTHS}
                                value={this.state.retentionPeriodValue}
                                onValueChanged={this.changeRetentionPeriodValue}
                                unit={<FormattedMessage id={'administration.months'} defaultMessage={'Months'}/>}
                                className={'no-controls width-50 text-center'}
                            />
                        </div>
                    </div>
                </div>
            </div>,
        );
    }

    retentionPeriodChanged() {
        return this.state.retentionPeriodValue !== this.props.retentionPeriodValue;
    }

    getFooter() {
        const hasChanges = this.retentionPeriodChanged();
        return (
            <div className={'btn-toolbar float-right'}>
                <a
                    className={'btn btn-default'}
                    onClick={this.closeSidebar}
                >
                    <FormattedMessage id={'cancel'} defaultMessage={'Cancel'} />
                </a>
                <a
                    className={`btn btn-primary ${hasChanges ? '' : 'disabled'}`}
                    onClick={this.save}
                >
                    <FormattedMessage id={'save'} defaultMessage={'Save'} />
                </a>
            </div>
        );
    }

    render() { // eslint-disable-line max-lines-per-function
        return (
            <Sidebar
                {...this.props.sidebarProps}
                footer={this.getFooter()}
            >
                {this.props.sidebarNavigation}
                {this.getRetentionPeriodsSection()}

                <ConfirmDownloadIntervalDialog
                    open={this.state.confirmDialogOpen}
                    confirm={this.closeConfirm}
                    abort={this.abortConfirm}
                    intervalSettingsChanged={false}
                    retentionPeriodChanged={this.retentionPeriodChanged()}
                    accessToken={this.props.accessToken}
                    retentionPeriodMonths={this.state.retentionPeriodValue}
                />
            </Sidebar>
        );
    }
}

CloudStorageSettingsSidebar.defaultProps = {
    retentionPeriodValue: 30,
    sidebarProps: { closed: true },
};

CloudStorageSettingsSidebar.propTypes = {
    accessToken: PropTypes.string.isRequired,
    permissions: PropTypes.array,

    setSettingsSidebarOpen: PropTypes.func,

    retentionPeriodValue: PropTypes.number,
    saveRetentionPeriod: PropTypes.func,

    sidebarProps: PropTypes.object,
    sidebarNavigation: PropTypes.node,
};

export default CloudStorageSettingsSidebar;
