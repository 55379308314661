/* eslint-disable max-lines-per-function */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import Switch from '@rio-cloud/rio-uikit/Switch';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import EmptyState from '@rio-cloud/rio-uikit/EmptyState';

import isEmpty from 'lodash/fp/isEmpty';
import isEqual from 'lodash/fp/isEqual';
import find from 'lodash/find';

import Tree from '@rio-cloud/rio-uikit/Tree';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { AutoSuggest } from '@rio-cloud/rio-uikit/AutoSuggest';

import DatePicker from '@rio-cloud/rio-uikit/DatePicker';

import moment from 'moment';

import Analytics from '../../components/common/Analytics';

import { SHOULD_INITIALLY_BE_RESPONSIVE} from '../../constants/common';

import TooltipTrigger from '../../components/common/TooltipTrigger';
import { env } from '../../env';

import ManualDownloadTable from './ManualDownloadTable';

const { isRunningOnProd } = env;

const COMPLETED = 3;

export const formatDriver = (cell, row) => {
    if (row.notIdentifiedYet) {
        return <span className={'text-muted'}>
            <FormattedMessage
                id={'administration.notIdentifiedYet'}
                defaultMessage={'Not identified yet'}
            />
        </span>;
    }

    return cell;
};

export const getNoDataState = () => (
    <div className={'display-flex justify-content-center height-75vh align-items-center'}>
        <EmptyState
            key={'ManualDownload-table-emptyState'}
            headline={<FormattedMessage id={'noDataHeadline'} defaultMessage={'No data'}/>}
            message={<FormattedMessage
                id={'administration.manualDownload.noData'}
                defaultMessage={'No remote downloads manually requested at the moment.'}
            />}
        />
    </div>
);

class ManualDownloadPageWithGroupsAndCancel extends Component {
    /* istanbul ignore next */
    constructor(props) {
        super(props);
        this.state = {
            isVehicleDialogOpen: false,
            isHintShown: false,
            isCancelDialogOpen: false,
            selectedIdList: [],
            isDriverChecked: false,
            isSpeedDataChecked: false,
            isCustomDateChecked: false,
            selectedGroupIds: [],
            selectedVehicleIds: [],
            expandedGroups: [],
            toDate: moment().endOf('day')
                .utc(),
            fromDate: moment().add(
                // eslint-disable-next-line no-magic-numbers
                -3,
                'months',
            )
                .startOf('day')
                .utc(),
            selectedAssetId: '',
            selectedVehicleDownloadRequestId: null,
            selectedDriverDownloadRequestId: null,
            isDriverOnlyChecked: false,
        };

        this.hideHint = this.hideHint.bind(this);
        this.requestDownload = this.requestDownload.bind(this);
        this.hideVehicleDialog = this.hideVehicleDialog.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
        this.handleExpandGroup = this.handleExpandGroup.bind(this);
        this.openCancelDialog = this.openCancelDialog.bind(this);
    }

    componentDidMount() {
        this.props.fetchLists();
    }

    componentDidUpdate() {
        if (this.props.successfulRequestDownload) {
            this.props.setSuccessfulRequestDownload(false);
            this.props.fetchLists();
        }
    }

    openCancelDialog(
        assetId,
        vehicleDownloadRequestId,
        driverDownloadRequestId,
    ) {
        this.setState({
            isCancelDialogOpen: true,
            selectedAssetId: assetId,
            selectedVehicleDownloadRequestId: vehicleDownloadRequestId,
            selectedDriverDownloadRequestId: driverDownloadRequestId,
        });
    }

    handleSelection({ items, groups }) {
        const vehiclesFromSelectedGroups = groups.reduce(
            (acc, el) => acc.concat(this.getActiveVehicles()
                .filter(vehicle => vehicle.groupIds.includes(el))),
            [],
        );
        const vehicleIdsFromSelectedGroups = vehiclesFromSelectedGroups.map(item => item.id);

        const listOfAllSelectedIds = vehicleIdsFromSelectedGroups.concat(items);
        const listOfUniqueIds = [...new Set(listOfAllSelectedIds)];
        this.setState(() => ({
            selectedVehicleIds: items,
            selectedGroupIds: groups,
            selectedIdList: listOfUniqueIds,
        }));
    }

    handleExpandGroup(expandedGroups) {
        this.setState(() => ({
            expandedGroups,
        }));
    }

    getActiveVehicles() {
        return this.props.listOfVehicles.map(vehicleReference => {
            const foundVehicleItem = find(
                this.props.vehicles,
                vehicleItem => isEqual(
                    vehicleReference.equipmentId,
                    vehicleItem.id,
                ),
            );

            if (this.props.vehicles.length > 0 && !foundVehicleItem) {
                return { notFound: true };
            }

            const vehicleName = foundVehicleItem ?
                foundVehicleItem.name :
                vehicleReference.equipmentId;

            const type = foundVehicleItem && foundVehicleItem.type ?
                foundVehicleItem.type.toLowerCase() :
                'truck';

            const groupIds = foundVehicleItem ? foundVehicleItem.groupIds : [];

            return {
                id: vehicleReference.equipmentId,
                disabled: !vehicleReference.isAvailable,
                name: vehicleName,
                type,
                groupIds,
            };
        })
            .filter(item => !item.notFound && !item.disabled);
    }

    getActiveGroups() {
        const groups = (this.props.vehicleGroupIds || [])
            .map(id => id === 'unassignedVehicles' ?
                {
                    id: 'unassignedVehicles',
                    name: this.props.intl.formatMessage({
                        id: 'unassignedVehicles',
                        defaultMessage: 'All unassigned vehicles',
                    }),
                } :
                this.props.tags[id] || { id, name: id });

        const activeDuplicatedGroupIds = this.getActiveVehicles().reduce(
            (acc, el) => acc.concat(el.groupIds),
            [],
        );
        const activeGroupIds = [...new Set(activeDuplicatedGroupIds)];

        const activeGroups = groups.filter(group => activeGroupIds.includes(group.id));
        return activeGroups;
    }

    getVehicleManualDownloadContent() {
        const activeVehicles = this.getActiveVehicles();

        const inputProps = {
            icon: 'rioglyph-search',
            value: this.state.search,
            className: '',
            onChange: this.setSearch,
            onClear: this.clearSearch,
            placeholder: this.props.intl.formatMessage({ id: 'pleaseSelect' }),
        };

        const isValidDateForToSelection = currentDate => {
            const start = moment(this.state.fromDate).startOf('day');
            const end = moment().endOf('day');
            return (
                currentDate.isBetween(
                    start,
                    end,
                    'days',
                    '[]',
                )
            );
        };

        const isValidDateForFromSelection = (currentDate, toDate) => {
            const endOfToday = moment().endOf('day');
            return currentDate.isBefore(endOfToday) && currentDate.isBefore(toDate);
        };

        const isEndDateBeforeStart = (firstDate, secondDate) => firstDate.clone()
            .isBefore(secondDate.clone());

        return (
            <div>
                <div className={'margin-bottom-5'}>
                    <span>
                        <FormattedMessage
                            id={'administration.selectVehiclesText'}
                            defaultMessage={'Select the vehicles for which you wish to request a manual download.'}/>
                        <OverlayTrigger
                            placement={'right'}
                            overlay={
                                <Tooltip
                                    id={'manualDownload.singleManualDownloadWarning'}
                                    width={350}
                                    className={'right'}>
                                    <span>
                                        <FormattedMessage
                                            id={'singleManualDownloadTooltip'}
                                            defaultMessage={'Please note that vehicles for which you have requested ' +
                                            'a download already or which had a download today will not be displayed.'}
                                        />
                                    </span>
                                </Tooltip>
                            }
                        >
                            <span className={'rioglyph rioglyph-info-sign text-primary margin-left-5'}/>
                        </OverlayTrigger>
                    </span>
                </div>
                <div className={'form-group'}>
                    <div className={'form-group'}>
                        { activeVehicles.length > 0 ?
                            <div className={'panel panel-default panel-body padding-0'}>
                                <Tree
                                    groups={this.getActiveGroups()}
                                    items={this.getActiveVehicles()}
                                    expandedGroups={this.state.expandedGroups}
                                    onExpandGroupsChange={this.handleExpandGroup}
                                    selectedGroups={this.state.selectedGroupIds}
                                    selectedItems={this.state.selectedVehicleIds}
                                    onSelectionChange={this.handleSelection}
                                    searchPlaceholder={this.props.intl.formatMessage({ id: 'pleaseSelect' })}
                                    scrollHeight={300}
                                />
                            </div> :
                            <AutoSuggest
                                suggestions={[]}
                                inputProps={inputProps}
                                noItemMessage={
                                    this.props.listOfVehiclesLoading ?
                                        <Spinner/> :
                                        <FormattedMessage
                                            id={'noVehicles'}
                                            defaultMessage={'There are no vehicles to display'}
                                        />
                                }
                                onSuggestionsFetchRequested={() => {}}
                                onSuggestionsClearRequested={() => {}}
                            />}
                    </div>
                </div>
                <div>
                    <div
                        className={'padding-right-10'}
                        data-testid={'SpeedDataSwitch'}
                    >
                        <Switch
                            checked={this.state.isSpeedDataChecked}
                            onChange={checked => this.setState({isSpeedDataChecked: checked})}
                            disabled={!!this.state.isDriverOnlyChecked}
                            labelPosition={'right'}
                        >
                            <span className={'text-size-14 margin-left-5'}>
                                <FormattedMessage
                                    id={'manualDownload.speedDataDownload'}
                                    defaultMessage={'Download Speed Data for selected vehicles.'}
                                />
                                <TooltipTrigger
                                    baseKey={'manualDownload.speedDataDownload'}
                                    tooltip={
                                        <FormattedMessage
                                            id={'settings.speedDataDownloadTooltip'}
                                            defaultMessage={
                                                'Warning: File size will increase and remote download ' +
                                                'will take longer when ' +
                                                'downloading speed data.'
                                            }
                                        />
                                    }
                                >
                                    <span className={'rioglyph rioglyph-info-sign text-primary margin-left-5'}/>
                                </TooltipTrigger>

                            </span>
                        </Switch>
                    </div>
                    <br/>
                    {!isRunningOnProd && <div
                        className={'padding-right-10'}
                        data-testid={'manualDateSwitch'}
                    >
                        <Switch
                            checked={this.state.isCustomDateChecked}
                            onChange={checked => this.setState({isCustomDateChecked: checked})}
                            disabled={!!this.state.isDriverOnlyChecked}
                            labelPosition={'right'}
                        >
                            <span className={'text-size-14 margin-left-5'}>
                                <FormattedMessage
                                    id={'manualDownload.manualDateSwitch'}
                                    defaultMessage={'Download a specific time period of the tachograph.'}
                                />
                                <TooltipTrigger
                                    baseKey={'manualDownload.manualDateSwitch'}
                                    tooltip={
                                        <FormattedMessage
                                            id={'manualDownload.manualDateSwitchTooltip'}
                                            defaultMessage={
                                                'Select start and end date for the period you ' +
                                                'want to request a manual download for.'
                                            }
                                        />
                                    }
                                >
                                    <span className={'rioglyph rioglyph-info-sign text-primary margin-left-5'}/>
                                </TooltipTrigger>

                            </span>
                        </Switch>
                        {this.state.isCustomDateChecked &&
                        <div className={'display-flex gap-10 max-width-400 flex-wrap'}>
                            <div className={'flex-1-1-0'}>
                                <label><FormattedMessage
                                    id={'from'}
                                    defaultMessage={'From'}
                                />:</label>
                                <DatePicker
                                    value={this.state.fromDate}
                                    inputProps={{
                                        placeholder: <FormattedMessage
                                            id={'manualDownload.datePlaceholder'}
                                            defaultMessage={'Select Date'}
                                        />,
                                        readOnly: true,
                                    }}
                                    onChange={selectedDate => {
                                        // eslint-disable-next-line no-negated-condition
                                        if (isEndDateBeforeStart(
                                            selectedDate.startOf('day'),
                                            this.state.toDate.endOf('day'),
                                        )) {
                                            this.setState({fromDate: selectedDate.clone().startOf('day')});
                                        } else {
                                            this.setState({toDate: selectedDate.clone().endOf('day')});
                                            this.setState({fromDate: selectedDate.clone().startOf('day')});
                                        }
                                    }}
                                    timeFormat={false}
                                    isValidDate={event => isValidDateForFromSelection(
                                        event,
                                        this.state.toDate,
                                    )}
                                />
                            </div>
                            <div className={'flex-1-1-0'}>
                                <label><FormattedMessage
                                    id={'to'}
                                    defaultMessage={'To'}
                                />:</label>
                                <DatePicker
                                    value={this.state.toDate}
                                    inputProps={{
                                        placeholder: <FormattedMessage
                                            id={'manualDownload.datePlaceholder'}
                                            defaultMessage={'Select Date'}
                                        />,
                                        readOnly: true,
                                    }}
                                    onChange={selectedDate => {
                                        if (isEndDateBeforeStart(
                                            selectedDate,
                                            this.state.fromDate,
                                        )) {
                                            this.setState({toDate: selectedDate.clone().endOf('day')});
                                            this.setState({fromDate: selectedDate.clone().startOf('day')});
                                        } else {
                                            this.setState({toDate: selectedDate.clone().endOf('day')});

                                        }
                                    }}
                                    timeFormat={false}
                                    isValidDate={isValidDateForToSelection}
                                    alignRight />
                            </div>
                            {this.state.isSpeedDataChecked &&
                                    <div className={'flex-1-1-1'}>
                                        <span className={'rioglyph rioglyph-info-sign text-color-info'}></span>
                                        <span>
                                            <FormattedMessage
                                                id={'manualDownoad.downloadWithSpeedLimit'}
                                                defaultMessage={
                                                    'At least speed data of the last 24 driving hours is available,' +
                                                    ' but most tachograph types store data for longer duration.'
                                                } /></span>
                                    </div>}
                        </div>}
                    </div>}
                </div>
            </div>
        );
    }

    getManualDownloadFooter() {
        return (
            <div className={'display-flex justify-content-between flex-wrap align-items-center width-100pct'}>
                <div
                    className={
                        'text-color-dark text-left display-flex align-items-center margin-top-10 margin-bottom-10'
                    }
                >
                    <div
                        className={'padding-right-10'}
                        data-testid={'DriverCardSwitch'}
                    >
                        <Switch
                            keyName={'driverSwitch'}
                            checked={this.state.isDriverChecked}
                            onChange={checked => this.setState({
                                isDriverChecked: checked,
                                // eslint-disable-next-line no-negated-condition
                                isDriverOnlyChecked: !checked ? false : this.state.isDriverOnlyChecked,
                            })}
                        >
                            <span
                                className={'text-size-14 margin-left-5'}>
                                <FormattedMessage
                                    id={'administration.requestDriverCardDownloadToo'}
                                    defaultMessage={'Request a driver card download for selected vehicles.'}
                                />
                            </span>
                        </Switch>
                        <br/>
                        <Switch
                            keyName={'driverOnlySwitch'}
                            checked={this.state.isDriverOnlyChecked}
                            onChange={checked => this.setState({
                                isDriverOnlyChecked: checked,
                                isDriverChecked: checked ? true : this.state.isDriverChecked,
                                isSpeedDataChecked: checked ? false : this.state.isSpeedDataChecked,
                                isCustomDateChecked: checked ? false : this.state.isCustomDateChecked,
                            })}
                        >
                            <span
                                className={'text-size-14 margin-left-5'}>
                                <FormattedMessage
                                    id={'administration.Only'}
                                    defaultMessage={'Request a driver card download only for selected vehicles.'}
                                />
                            </span>
                        </Switch>
                    </div>
                </div>
                <div className={'padding-right-0'}>
                    <button
                        data-testid={'OpenVehicleDialogRequestButton'}
                        className={'btn btn-primary'}
                        disabled={isEmpty(this.state.selectedVehicleIds) && isEmpty(this.state.selectedGroupIds)}
                        onClick={() => {
                            this.setState({
                                isVehicleDialogOpen: false,
                                isHintShown: true,
                            });
                        }}>
                        <FormattedMessage id={'archive.requestDownload'}/>
                    </button>
                </div>
            </div>
        );
    }

    hideVehicleDialog() {
        this.setState({ isVehicleDialogOpen: false, isCancelDialogOpen: false });
    }

    getVehicleManualDownloadDialog() {
        return (<Dialog
            show={this.state.isVehicleDialogOpen}
            title={<FormattedMessage id={'archive.manualDownload'} />}
            body={this.getVehicleManualDownloadContent()}
            footer={this.getManualDownloadFooter()}
            onHide={this.hideVehicleDialog}/>);
    }

    getCancelManualDownloadDialog() {
        return (<ConfirmationDialog
            show={this.state.isCancelDialogOpen}
            title={<FormattedMessage
                id={'manualDownload.cancelManualDialogTitle'}
                defaultMessage={'Cancel Manual Download'}/>}
            content={this.getCancelManualDownloadContent()}
            onClickConfirm={() => {
                this.props.cancelDownload({
                    assetId: this.state.selectedAssetId,
                    vehicleDownloadRequestId: this.state.selectedVehicleDownloadRequestId,
                    driverDownloadRequestId: this.state.selectedDriverDownloadRequestId,
                });
                this.setState({
                    isCancelDialogOpen: false,
                    selectedVehicleDownloadRequestId: null,
                    selectedDriverDownloadRequestId: null,
                });
            }}
            onClickCancel={this.hideVehicleDialog}
            cancelButtonText={<FormattedMessage id={'abort'}/>}
            confirmButtonText={<FormattedMessage id={'confirm'}/>}
            useOverflow={true}
        />);
    }

    // eslint-disable-next-line class-methods-use-this
    getCancelManualDownloadContent() {
        return (
            <div className={'ConfirmationContent'}>
                <p>
                    <FormattedMessage
                        id={'manualDownload.cancelManualDownloadContent'}
                        defaultMessage={'Are you sure you wish to cancel this manual download?'}/>
                </p>
            </div>
        );
    }

    getConfirmationContent() {
        const marginRightClass = this.state.isDriverChecked ? ' margin-right-5' : '';

        const suggestion = this.getActiveVehicles().filter(item => this.state.selectedIdList.includes(item.id));
        const list = suggestion.map(vehicle => <li
            key={`selected-final-list-element-${vehicle.id}`}
            className={'list-group-item display-flex justify-content-between align-items-center'}
            data-testid={`ConfirmationContentListItem-${vehicle.id}`}
        >
            <span>
                <span className={`rioglyph rioglyph-${vehicle.type} padding-right-5`}/>
                <span>{vehicle.name}</span>
            </span>
            <span>
                {
                    this.state.isSpeedDataChecked &&
                <span className={ `label label-muted label-filled${marginRightClass}`}>
                    <span className={'rioglyph rioglyph-speed padding-right-5'}/>
                    <FormattedMessage id={'archive.speedData'} defaultMessage={'Speed Data'}/>
                </span>
                }
                {
                    this.state.isDriverChecked &&
                <span className={'label label-muted label-filled'}>
                    <span className={'rioglyph rioglyph-driver padding-right-5'}/>
                    <FormattedMessage id={'driverCard'} defaultMessage={'Driver Card'}/>
                </span>
                }
            </span>
        </li>);

        return (
            <div className={'ConfirmationContent'}>
                <p>
                    <FormattedMessage id={'administration.confirmationText1'}/>
                </p>
                <p>
                    <a
                        href={this.props.intl.formatMessage({ id: 'administration.manualLink' })}
                        target={'_blank'}
                        rel={'noreferrer'}
                    >
                        <span className={'rioglyph rioglyph-link'}/>
                        <FormattedMessage id={'administration.instructionManual'} />
                    </a>
                </p>
                <p>
                    <FormattedMessage
                        id={'administration.amountConfirmationText2'}
                        values={{ amount: this.state.selectedIdList.length }}
                        defaultMessage={'Are you sure you wish to request a manual download for {amount} vehicles?'}/>
                </p>
                {this.state.isCustomDateChecked &&
                 <p><FormattedMessage
                     id={'manualDownload.dateConfirmationText'}
                     values={{
                         start: this.state.fromDate.startOf('day').format('DD/MM/YYYY'),
                         end: this.state.toDate.endOf('day').format('DD/MM/YYYY'),
                     }}
                     defaultMessage={'Selected date range from {start} to {end}'}/></p>}
                {this.state.isDriverOnlyChecked &&
                 <p><span className={'rioglyph rioglyph-info-sign text-primary margin-left-5'}/><FormattedMessage
                     id={'manualDownload.driverDownloadOnlyConfirmation'}
                     defaultMessage={'Driver card download only will be requested'}/></p>}
                <ul className={'list-group border rounded'}>
                    {list}
                </ul>
            </div>
        );
    }

    hideHint() {
        this.setState({ isHintShown: false });
    }

    requestDownload() {
        const {selectedIdList, isDriverChecked, isSpeedDataChecked, fromDate, toDate} = this.state;
        if (this.state.isDriverOnlyChecked) {
            this.props.requestDownload({
                vehicleIds: selectedIdList,
                includeDriverData: isDriverChecked,
                downloadDriverOnly: true,
            });
        } else if (this.state.isCustomDateChecked) {
            this.props.requestDownload({
                vehicleIds: selectedIdList,
                includeDriverData: isDriverChecked,
                useSpeedData: isSpeedDataChecked,
                startDateUtc: fromDate.startOf('day'),
                endDateUtc: toDate.endOf('day'),
                downloadDriverOnly: false,
            });
        } else {
            this.props.requestDownload({
                vehicleIds: selectedIdList,
                includeDriverData: isDriverChecked,
                useSpeedData: isSpeedDataChecked,
                startDateUtc: null,
                endDateUtc: null,
                downloadDriverOnly: false,
            });
        }

        this.setState({
            selectedGroupIds: [],
            selectedVehicleIds: [],
            expandedGroups: [],
            selectedIdList: [],
            isHintShown: false,
        });
    }

    getConfirmationDialog() {
        return (
            <ConfirmationDialog
                show={this.state.isHintShown}
                title={<FormattedMessage id={'archive.manualDownload'}/>}
                content={this.getConfirmationContent()}
                onClickConfirm={this.requestDownload}
                onClickCancel={this.hideHint}
                cancelButtonText={<FormattedMessage id={'abort'}/>}
                confirmButtonText={<FormattedMessage id={'confirm'}/>}
                useOverflow={true}
            />
        );
    }

    getDriverInfos(driverRequest) {
        if (driverRequest && !isEqual(
            driverRequest,
            null,
        )) {
            const foundDriverItem = find(
                this.props.drivers,
                driverItem => isEqual(
                    driverRequest.equipmentId,
                    driverItem.id,
                ),
            );

            const driverNameSearched = foundDriverItem ?
                `${foundDriverItem.name.first_name} ${foundDriverItem.name.last_name}` :
                driverRequest.equipmentId;

            const driverName = driverRequest && driverRequest.driverName && driverRequest.driverName !== null ?
                driverRequest.driverName :
                driverNameSearched;

            return {
                driver: driverName,
                statusDriver: driverRequest.status,
                cancelDriver: driverRequest.status,
                transferDateDriver: driverRequest.status === COMPLETED ? driverRequest.processed : '',
                notIdentifiedYet: driverName && driverName[0] === 'X',
                driverDownloadRequestId: driverRequest.downloadRequestId,
            };
        }

        return {};
    }

    getData() {
        return this.props.listOfRequests.map((request, index) => {

            const foundVehicleItem = find(
                this.props.vehicles,
                vehicleItem => isEqual(
                    request.vehicle.equipmentId,
                    vehicleItem.id,
                ),
            );

            const vehicleName = foundVehicleItem ?
                foundVehicleItem.name :
                request.vehicle.equipmentId;

            const driverInfos = this.getDriverInfos(request.driver);

            return {
                requestId: index,
                vehicleDownloadRequestId: request.vehicle.downloadRequestId,
                requestDate: request.vehicle.requested,
                vehicle: vehicleName,
                assetId: request.vehicle.equipmentId,
                statusVehicle: request.vehicle.status,
                transferDateVehicle: request.vehicle.status === COMPLETED ? request.vehicle.processed : '',
                ...driverInfos,
                periodStart: request.vehicle.periodStart,
                periodEnd: request.vehicle.periodEnd,
                vehicleCancel: request.vehicle.status,
            };
        });
    }

    render() { // eslint-disable-line max-lines-per-function
        const isLoading = this.props.listOfRequests.length === 1 &&
            this.props.listOfRequests[0].vehicle &&
            this.props.listOfRequests[0].vehicle.status === 'loading';

        const data = this.getData();

        return (
            <Analytics page={'/ManualDownloadPage'}>
                <div className={'ManualDownloadPage'}>
                    <div className={'row'}>
                        <div className={'col-xs-12'}>
                            <button
                                className={'OpenVehicleDialogButton btn btn-default form-group z-index-1'}
                                onClick={() => this.setState({
                                    isVehicleDialogOpen: true,
                                })}
                            >
                                <FormattedMessage id={'archive.requestDownload'}/>
                            </button>
                        </div>
                    </div>
                    {
                        isLoading ?
                            <div data-testid= {'ManualDownloadPageSpinner'}><Spinner/></div> :
                            <div className={'row'}>
                                <div className={'col-xs-12'}>
                                    <div className={'form-group'}>
                                        {
                                            data.length ?
                                                <ManualDownloadTable
                                                    openCancelDialog={this.openCancelDialog}
                                                    vehicleList={data}
                                                    cancelDownload={this.props.cancelDownload}
                                                ></ManualDownloadTable> :
                                                getNoDataState()
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                    { this.getVehicleManualDownloadDialog() }
                    { this.getConfirmationDialog() }
                    { this.getCancelManualDownloadDialog()}
                </div>
            </Analytics>
        );
    }
}

ManualDownloadPageWithGroupsAndCancel.defaultProps = {
    drivers: [],
    vehicles: [],
    listOfRequests: [],
    listOfVehicles: [],
    beResponsive: SHOULD_INITIALLY_BE_RESPONSIVE,
};

ManualDownloadPageWithGroupsAndCancel.propTypes = {
    listOfRequests: PropTypes.array,
    listOfVehicles: PropTypes.array,
    listOfVehiclesLoading: PropTypes.bool,
    drivers: PropTypes.array,
    vehicles: PropTypes.array,
    requestDownload: PropTypes.func,
    cancelDownload: PropTypes.func,
    fetchLists: PropTypes.func,
    successfulRequestDownload: PropTypes.bool,
    setSuccessfulRequestDownload: PropTypes.func,
    intl: PropTypes.object,
    beResponsive: PropTypes.bool,
    vehicleGroupIds: PropTypes.array,
    tags: PropTypes.object,
};

export default injectIntl(ManualDownloadPageWithGroupsAndCancel);
