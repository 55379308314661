import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import cloneDeep from 'lodash/fp/cloneDeep';

import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import Slider from '@rio-cloud/rio-uikit/Slider';
import NumberInput from '@rio-cloud/rio-uikit/NumberInput';
import Switch from '@rio-cloud/rio-uikit/Switch';

import Spinner from '@rio-cloud/rio-uikit/Spinner';

import {
    DRIVERS_MAX_INTERVAL,
    VEHICLES_MAX_INTERVAL,
} from '../../../constants/archive';

import { getMinValues } from '../../Overview/OverviewPage';

import TooltipTrigger from '../../../components/common/TooltipTrigger';

import IntervalSettingsListGroup from './IntervalSettingsListGroup';
import ConfirmDownloadIntervalDialog from './ConfirmDownloadIntervalDialog';

const borderClass = 'border-width-1 border-color-lighter border-style-solid border-right-0 border-left-0 border-top-0';
export const paddingClass = 'padding-20';
export const rowClass = `${borderClass} ${paddingClass}`;
export const lastRowClass = `${rowClass} border-bottom-0`;

export class IntervalSettingsSidebarComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tachoValue: props.fleetDownloadIntervals.vehicleDownloadInterval,
            driverValue: props.fleetDownloadIntervals.driverDownloadInterval,
            confirmDialogOpen: false,
            removedDriverCards: [],
            removedEquipmentIds: [],
            speedDataDownload: props.hasSpeedData,
        };

        this.changeTachoValue = this.changeTachoValue.bind(this);
        this.changeDriverValue = this.changeDriverValue.bind(this);
        this.saveValues = this.saveValues.bind(this);
        this.closeSidebar = this.closeSidebar.bind(this);
        this.save = this.save.bind(this);
        this.closeConfirm = this.closeConfirm.bind(this);
        this.abortConfirm = this.abortConfirm.bind(this);
    }

    componentDidMount() {
        this.props.getDownloadIntervals();
    }

    componentDidUpdate(prevProps) {
        if (this.props.shouldRefetch && !prevProps.shouldRefetch) {
            this.props.getDownloadIntervals();
        }
    }

    removeDriverCard(id) {
        const newCards = cloneDeep(this.state.removedDriverCards);
        newCards.push(id);

        this.setState({ removedDriverCards: newCards });
    }

    removedEquipmentId(id) {
        const newIds = cloneDeep(this.state.removedEquipmentIds);
        newIds.push(id);

        this.setState({ removedEquipmentIds: newIds });
    }

    changeTachoValue(value) {
        this.setState({ tachoValue: value });
    }

    changeDriverValue(value) {
        this.setState({ driverValue: value });
    }

    saveValues() {
        this.props.setDownloadIntervals({
            fleetDownloadIntervals: {
                vehicleDownloadInterval: this.state.tachoValue,
                driverDownloadInterval: this.state.driverValue,
            },
            customDownloadIntervals: this.props.customDownloadIntervals,
            customIntervalsToRemove: {
                equipmentIds: this.state.removedEquipmentIds,
                fullDriverCards: this.state.removedDriverCards,
            },
            useSpeedData: this.state.speedDataDownload,
        });
    }

    closeSidebar() {
        this.props.setSettingsSidebarOpen(false);
    }

    save() {
        this.setState({
            confirmDialogOpen: true,
        });

        try {
            document.getElementsByClassName('ApplicationHeader')[0].classList.add('z-index-1');
        } catch (error) {
            console.log(error); // eslint-disable-line no-console
        }
    }

    closeConfirm() {
        this.abortConfirm();

        if (this.intervalSettingsChanged()) {
            this.saveValues();
        }

        this.closeSidebar();
    }

    abortConfirm() {
        this.setState({
            confirmDialogOpen: false,
        });

        try {
            document
                .getElementsByClassName('ApplicationHeader')[0]
                .classList
                .remove('z-index-1');
        } catch (error) {
            console.log(error); // eslint-disable-line no-console
        }
    }

    getVehicleName(id) {
        const assetTreeVehicleItem = this.props.vehicles.find(vehicle => vehicle.id === id) || {};

        return assetTreeVehicleItem ? assetTreeVehicleItem.name : false;
    }

    getDriverName(id) {
        const assetTreeDriverItem = this.props.drivers.find(driver => driver.driverCard === id);

        return assetTreeDriverItem ?
            `${assetTreeDriverItem.display_name}` :
            false;
    }

    getCustomizedIntervalSettings() {
        return this.props.customDownloadIntervals.vehicleDownloadIntervals
            .map(vehicleSetting => ({
                ...vehicleSetting,
                type: 'truck',
                id: vehicleSetting.equipmentId,
                name: this.getVehicleName(vehicleSetting.equipmentId),
                onRemove: () => this.removedEquipmentId(vehicleSetting.equipmentId),
            }))
            .filter(vehicleSetting => !this.state.removedEquipmentIds.includes(vehicleSetting.equipmentId))
            .concat(this.props.customDownloadIntervals.driverDownloadIntervals.map(driverSetting => ({
                ...driverSetting,
                type: 'driver',
                id: driverSetting.fullDriverCard,
                name: this.getDriverName(driverSetting.fullDriverCard),
                onRemove: () => this.removeDriverCard(driverSetting.fullDriverCard),
            }))
                .filter(driverSetting => !this.state.removedDriverCards.includes(driverSetting.fullDriverCard)));
    }

    getVehiclesSlider() {
        const { vehiclesMinInterval } = getMinValues();
        return (
            <div className={'display-flex align-items-center margin-bottom-10'}>
                <Slider
                    value={this.state.tachoValue}
                    minValue={vehiclesMinInterval}
                    maxValue={VEHICLES_MAX_INTERVAL}
                    onChange={this.changeTachoValue}
                />
                <div className={'margin-left-10'} style={{ minWidth: '110px' }}>
                    <NumberInput
                        min={vehiclesMinInterval}
                        max={VEHICLES_MAX_INTERVAL}
                        value={this.state.tachoValue}
                        onValueChanged={this.changeTachoValue}
                        unit={<FormattedMessage id={'administration.days'} defaultMessage={'Days'}/>}
                        className={'no-controls width-50 text-center'}
                    />
                </div>
            </div>
        );
    }

    getDriversSlider() {
        const { driversMinInterval } = getMinValues();
        return (
            <div className={'display-flex align-items-center'}>
                <Slider
                    value={this.state.driverValue}
                    minValue={driversMinInterval}
                    maxValue={DRIVERS_MAX_INTERVAL}
                    onChange={this.changeDriverValue}
                />
                <div className={'margin-left-10'} style={{ minWidth: '110px' }}>
                    <NumberInput
                        min={driversMinInterval}
                        max={DRIVERS_MAX_INTERVAL}
                        value={this.state.driverValue}
                        onValueChanged={this.changeDriverValue}
                        unit={<FormattedMessage id={'administration.days'} defaultMessage={'Days'}/>}
                        className={'no-controls width-50 text-center'}
                    />
                </div>
            </div>
        );
    }

    getDefaultDownloadIntervalsSection() {
        return (
            <div className={rowClass}>
                <div className={'display-block'}>
                    <div className={'SidebarHeader padding-0 height-25 form-group'}>
                        <FormattedMessage
                            id={'administration.defaultDownloadIntervalSettings'}
                            defaultMessage={'Default download interval settings'}
                        />
                    </div>
                </div>
                <div className={'display-block'}>
                    <div>
                        <span className={'rioglyph rioglyph-truck margin-right-5'}/>
                        <FormattedMessage
                            id={'administration.tachographDownloadInterval'}
                            defaultMessage={'Tachograph download interval'}
                        />
                    </div>
                    {this.getVehiclesSlider()}
                    <div className={'IntervalSettingsCustomSwitch'}>
                        <Switch
                            checked={this.state.speedDataDownload}
                            onChange={() => this.setState({speedDataDownload: !this.state.speedDataDownload})}
                            labelPosition={'right'}
                        >
                            <span >
                                <FormattedMessage
                                    id={'settings.speedDataDownload'}
                                    defaultMessage={'Download Speed Data'}
                                />
                                <TooltipTrigger
                                    baseKey={'settings.speedDataDownload'}
                                    tooltip={
                                        <FormattedMessage
                                            id={'settings.speedDataDownloadTooltip'}
                                            defaultMessage={
                                                'Warning: File size will increase and remote download ' +
                                                'will take longer when ' +
                                                'downloading speed data.'
                                            }
                                        />
                                    }
                                >
                                    <span className={'rioglyph rioglyph-info-sign text-primary margin-left-5'}/>
                                </TooltipTrigger>
                            </span>
                        </Switch>
                    </div>
                </div>
                <div className={'display-block'}>
                    <div className={`${borderClass} margin-top-20 margin-bottom-20`}/>
                </div>
                <div className={'display-block'}>
                    <div>
                        <span className={'rioglyph rioglyph-driver margin-right-5'}/>
                        <FormattedMessage
                            id={'administration.driverCardDownloadInterval'}
                            defaultMessage={'Driver card download interval'}
                        />
                    </div>
                    { this.getDriversSlider() }
                </div>
            </div>
        );
    }

    getCustomizedDownloadIntervalsSection() {
        return (
            <div className={lastRowClass}>
                <div className={'display-block'}>
                    <div className={'SidebarHeader padding-0 height-25 form-group'}>
                        <FormattedMessage
                            id={'administration.customizedDownloadIntervalSettings'}
                            defaultMessage={'Customized download interval settings'}
                        />
                    </div>
                    { this.props.customDownloadIntervals.loading ?
                        <Spinner/> :
                        <IntervalSettingsListGroup customizedIntervalSettings={this.getCustomizedIntervalSettings()}/>
                    }
                </div>
            </div>
        );
    }

    intervalSettingsChanged() {
        const {
            removedDriverCards,
            removedEquipmentIds,
            tachoValue,
            driverValue,
            speedDataDownload,
        } = this.state;

        const {
            driverDownloadInterval,
            vehicleDownloadInterval,
        } = this.props.fleetDownloadIntervals;

        return (
            removedDriverCards.length > 0 ||
            removedEquipmentIds.length > 0 ||
            driverValue !== driverDownloadInterval ||
            tachoValue !== vehicleDownloadInterval ||
            speedDataDownload !== this.props.hasSpeedData

        );
    }

    getFooter() {
        const hasChanges = this.intervalSettingsChanged();
        return (
            <div className={'btn-toolbar float-right'}>
                <a
                    className={'btn btn-default'}
                    onClick={this.closeSidebar}
                >
                    <FormattedMessage id={'cancel'} defaultMessage={'Cancel'} />
                </a>
                <a
                    className={`btn btn-primary ${hasChanges ? '' : 'disabled'}`}
                    onClick={this.save}
                >
                    <FormattedMessage id={'save'} defaultMessage={'Save'} />
                </a>
            </div>
        );
    }

    render() { // eslint-disable-line max-lines-per-function
        return (
            <Sidebar
                {...this.props.sidebarProps}
                footer={this.getFooter()}
            >
                {this.props.sidebarNavigation}
                {this.getDefaultDownloadIntervalsSection()}
                {this.getCustomizedDownloadIntervalsSection()}

                <ConfirmDownloadIntervalDialog
                    open={this.state.confirmDialogOpen}
                    confirm={this.closeConfirm}
                    abort={this.abortConfirm}
                    intervalSettingsChanged={this.intervalSettingsChanged()}
                    retentionPeriodChanged={false}
                    accessToken={this.props.accessToken}
                />
            </Sidebar>
        );
    }
}

IntervalSettingsSidebarComponent.defaultProps = {
    sidebarProps: { closed: true },
};

IntervalSettingsSidebarComponent.propTypes = {
    intl: PropTypes.object.isRequired,
    accessToken: PropTypes.string.isRequired,

    drivers: PropTypes.array,
    vehicles: PropTypes.array,

    setSettingsSidebarOpen: PropTypes.func,
    setDownloadIntervals: PropTypes.func,

    customizedIntervalSettings: PropTypes.array,

    fleetDownloadIntervals: PropTypes.object,
    customDownloadIntervals: PropTypes.object,
    hasSpeedData: PropTypes.bool,
    shouldRefetch: PropTypes.bool,

    getDownloadIntervals: PropTypes.func,

    sidebarProps: PropTypes.object,
    sidebarNavigation: PropTypes.node,
};

export default injectIntl(IntervalSettingsSidebarComponent);
