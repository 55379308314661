import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/bg";
import "@formatjs/intl-pluralrules/locale-data/cs";
import "@formatjs/intl-pluralrules/locale-data/da";
import "@formatjs/intl-pluralrules/locale-data/de";
import "@formatjs/intl-pluralrules/locale-data/el";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/locale-data/es";
import "@formatjs/intl-pluralrules/locale-data/et";
import "@formatjs/intl-pluralrules/locale-data/fi";
import "@formatjs/intl-pluralrules/locale-data/fr";
import "@formatjs/intl-pluralrules/locale-data/hr";
import "@formatjs/intl-pluralrules/locale-data/hu";
import "@formatjs/intl-pluralrules/locale-data/it";
import "@formatjs/intl-pluralrules/locale-data/lt";
import "@formatjs/intl-pluralrules/locale-data/lv";
import "@formatjs/intl-pluralrules/locale-data/nb";
import "@formatjs/intl-pluralrules/locale-data/nl";
import "@formatjs/intl-pluralrules/locale-data/no";
import "@formatjs/intl-pluralrules/locale-data/pl";
import "@formatjs/intl-pluralrules/locale-data/pt";
import "@formatjs/intl-pluralrules/locale-data/ro";
import "@formatjs/intl-pluralrules/locale-data/sk";
import "@formatjs/intl-pluralrules/locale-data/sl";
import "@formatjs/intl-pluralrules/locale-data/sv";
import messagesEN from './tachographservices_en-GB.json';
export const defaultMessages = messagesEN;
export const DEFAULT_LANGUAGE = 'en-GB';
export const SUPPORTED_LANGUAGES = {
    en: 'en-GB',
    'en-GB': 'en-GB',
    'en-US': 'en-GB',
    de: 'de-DE',
    'de-DE': 'de-DE',
    cs: 'cs-CZ',
    'cs-CZ': 'cs-CZ',
    da: 'da-DK',
    'da-DK': 'da-DK',
    es: 'es-ES',
    'es-ES': 'es-ES',
    fr: 'fr-FR',
    'fr-FR': 'fr-FR',
    it: 'it-IT',
    'it-IT': 'it-IT',
    nl: 'nl-NL',
    'nl-NL': 'nl-NL',
    pl: 'pl-PL',
    'pl-PL': 'pl-PL',
    ro: 'ro-RO',
    'ro-RO': 'ro-RO',
    bg: 'bg-BG',
    'bg-BG': 'bg-BG',
    hr: 'hr-HR',
    'hr-HR': 'hr-HR',
    fi: 'fi-FI',
    'fi-FI': 'fi-FI',
    hu: 'hu-HU',
    'hu-HU': 'hu-HU',
    lt: 'lt-LT',
    'lt-LT': 'lt-LT',
    nb: 'nb-NO',
    'nb-NO': 'nb-NO',
    pt: 'pt-PT',
    'pt-PT': 'pt-PT',
    sk: 'sk-SK',
    'sk-SK': 'sk-SK',
    sl: 'sl-SI',
    'sl-SI': 'sl-SI',
    'sl-SL': 'sl-SI',
    se: 'sv-SE',
    'sv-SE': 'sv-SE',
    el: 'el-GR',
    'el-GR': 'el-GR',
    et: 'et-EE',
    'et-EE': 'et-EE',
    lv: 'lv-LV',
    'lv-LV': 'lv-LV',
};
/* istanbul ignore next */
const browserLocale = navigator.language || DEFAULT_LANGUAGE;
export const getSupportedLanguageKey = (
// istanbul ignore next
languageKey = navigator.language) => {
    // istanbul ignore next
    if (languageKey !== null) {
        const langKeyShort = languageKey.split('-')[0];
        if (SUPPORTED_LANGUAGES[languageKey]) {
            return SUPPORTED_LANGUAGES[languageKey];
        }
        else if (SUPPORTED_LANGUAGES[langKeyShort]) {
            return SUPPORTED_LANGUAGES[langKeyShort];
        }
    }
    // istanbul ignore next
    const browserLocaleShort = browserLocale.split('-')[0];
    // istanbul ignore next
    if (SUPPORTED_LANGUAGES[browserLocale]) {
        return SUPPORTED_LANGUAGES[browserLocale];
    }
    else if (SUPPORTED_LANGUAGES[browserLocaleShort]) {
        return SUPPORTED_LANGUAGES[browserLocaleShort];
    }
    // istanbul ignore next
    return DEFAULT_LANGUAGE;
};
