/* eslint-disable no-case-declarations */
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

import {
    SUCCESSFUL_FETCH_ASSETS,
    SUCCESSFUL_FETCH_DRIVERS,
    SUCCESSFUL_FETCH_TAGS,
    SET_SELECTED_VEHICLES,
    SET_SELECTED_DRIVERS,
    SET_CURRENT_CATEGORY,
    IS_ASSET_TREE_OPEN,
} from '../actions/tree';
import {SET_VEHICLES_WITH_SUBSCRIPTION} from '../actions/common';
import {SHOULD_INITIALLY_BE_RESPONSIVE} from '../constants/common';

export const initialState = {
    vehiclesWithSubscription: [],
    vehicleGroupIds: [],
    vehicleIdsByGroup: {},
    driverIdsByGroup: {},
    driverGroupIds: [],
    vehicles: [],
    rawVehicles: [],
    rawDrivers: [],
    selectedVehicles: [],
    drivers: [],
    selectedDrivers: [],
    selectedDriverCards: [],
    tags: {},
    currentCategoryId: 'AssetTree-vehicles',
    isAssetTreeOpen: !SHOULD_INITIALLY_BE_RESPONSIVE,
};

const getIsVehicle = vehicleId => vehicle => vehicle.id === vehicleId || vehicle.identification === vehicleId;

const findVehicle = (assets, vehicleId) => assets.find(getIsVehicle(vehicleId));

const getVehicle = (assets, vehicleId) => findVehicle(
    assets,
    vehicleId,
) ||
    { id: vehicleId, name: vehicleId, type: 'truck' };

const getVehicles = (assets, vehiclesWithSubscription) => vehiclesWithSubscription.length ?
    vehiclesWithSubscription.map(vehicle => getVehicle(
        assets,
        vehicle.assetId,
    )) :
    assets;

const getGroups = (assets, unassignedTag = 'unassignedVehicles', tagsPath = '_embedded.tags.items') => {
    const assetGroupIds = new Set();
    const assetIdsByGroup = { [unassignedTag]: [] };

    assets.forEach(asset => {
        const tags = get(
            asset,
            tagsPath,
            [],
        );
        tags.forEach(tag => {
            assetGroupIds.add(tag.id);

            if (assetIdsByGroup[tag.id]) {
                assetIdsByGroup[tag.id].push(asset.id);
            } else {
                assetIdsByGroup[tag.id] = [asset.id];
            }
        });

        if (!tags.length) {
            assetGroupIds.add(unassignedTag);
            assetIdsByGroup[unassignedTag].push(asset.id);
        }
    });

    return { assetGroupIds, assetIdsByGroup };
};

const getAssetState = (assets, vehiclesWithSubscription) => {
    const vehicles = getVehicles(
        assets,
        vehiclesWithSubscription,
    );
    const { assetGroupIds, assetIdsByGroup } = getGroups(
        vehicles,
        'unassignedVehicles',
    );

    return {
        vehicles:
            sortBy(
                vehicles.map(vehicle => ({
                    ...vehicle,
                    groupIds:
                        get(
                            vehicle,
                            '_embedded.tags.items',
                            [],
                        ).length ?
                            get(
                                vehicle,
                                '_embedded.tags.items',
                                [],
                            ).map(tag => tag.id) :
                            ['unassignedVehicles'],
                })),
                'name',
            ),
        vehicleIdsByGroup: assetIdsByGroup,
        vehicleGroupIds:
            sortBy(
                Array.from(assetGroupIds),
                'name',
            ),
        vehiclesWithSubscription,
    };
};

const getDriversState = (drivers = []) => {
    const { assetGroupIds, assetIdsByGroup } = getGroups(
        drivers,
        'unassignedDrivers',
        '_embedded.tags',
    );

    return {
        drivers,
        driverIdsByGroup: assetIdsByGroup,
        driverGroupIds:
            sortBy(
                Array.from(assetGroupIds),
                'name',
            ),
    };
};

const getLatestCard = driverCards => {
    // the intention is to compare cards based on last two digits and take the one with higher value

    const firstCard = driverCards.length > 0 ? driverCards[0] : {};

    // eslint-disable-next-line immutable/no-let
    let latestCard = firstCard.identification;
    driverCards.forEach(card => {
        if (latestCard.localeCompare(card.identification) < 0) {
            latestCard = card.identification;
        }
    });

    return latestCard;
};

const tree = (state = initialState, action = {}) => { // eslint-disable-line max-lines-per-function
    switch (action.type) {
        case SUCCESSFUL_FETCH_ASSETS:
            return {
                ...state,
                ...getAssetState(
                    action.payload,
                    state.vehiclesWithSubscription || [],
                ),
                rawVehicles: action.payload,
            };
        case SUCCESSFUL_FETCH_DRIVERS:
            return {
                ...state,
                ...getDriversState(sortBy(
                    action.payload.map(driver => ({
                        ...driver,
                        name:
                                driver.last_name || driver.last_name ?
                                    {
                                        lastName: driver.last_name,
                                        firstName: driver.first_name,
                                    } :
                                    {
                                        lastName: (
                                            get(
                                                driver,
                                                '_embedded.identifications',
                                                [],
                                            )
                                                .find(identification => identification
                                                    .identification_type === 'eu-tachograph-card') ||
                                            {}
                                        ).identification,
                                    },
                        type: 'driver',
                        groupIds:
                                get(
                                    driver,
                                    '_embedded.tags',
                                    [],
                                ).length ?
                                    get(
                                        driver,
                                        '_embedded.tags',
                                        [],
                                    ).map(tag => tag.id) :
                                    ['unassignedDrivers'],
                        driverCard:
                        getLatestCard(get(
                            driver,
                            '_embedded.identifications',
                            [],
                        )
                            .filter(identification => identification
                                .identification_type === 'eu-tachograph-card')),
                        driverCards:
                                get(
                                    driver,
                                    '_embedded.identifications',
                                    [],
                                )
                                    .filter(identification => identification
                                        .identification_type === 'eu-tachograph-card')
                                    .map(identification => identification.identification),
                        driverId:
                            (
                                get(
                                    driver,
                                    '_embedded.identifications',
                                    [],
                                )
                                    .find(identification => identification
                                        .identification_type === 'rio-driver-identification') ||
                                {}
                            ).identification,
                        driverIds:
                                get(
                                    driver,
                                    '_embedded.identifications',
                                    [],
                                )
                                    .filter(identification => identification
                                        .identification_type === 'rio-driver-identification')
                                    .map(identification => identification.identification)
                                    .concat([driver.id]),
                    })),
                    ['name.firstName', 'name.lastName'],
                )),
                rawDrivers: action.payload,
            };
        case SUCCESSFUL_FETCH_TAGS:
            return {
                ...state,
                tags: reduce(
                    action.payload,
                    (res, tag) => ({ ...res, [tag.id]: tag }),
                    {
                        unassignedVehicles: {
                            id: 'unassignedVehicles',
                            name: 'All unassigned vehicles',
                        },
                        unassignedDrivers: {
                            id: 'unassignedDrivers',
                            name: 'All unassigned drivers',
                        },
                    },
                ),
            };
        case SET_SELECTED_VEHICLES:
            return {
                ...state,
                selectedVehicles: action.payload,
                selectedDrivers: [],
                selectedDriverCards: [],
            };
        case SET_SELECTED_DRIVERS:
            return {
                ...state,
                selectedVehicles: [],
                selectedDrivers: action.payload,
                selectedDriverCards: action.payload
                    .map(id => ((state.drivers || []).find(driver => driver.id === id) || {}).driverCard),
            };
        case SET_CURRENT_CATEGORY:
            return {
                ...state,
                currentCategoryId: action.payload,
            };
        case SET_VEHICLES_WITH_SUBSCRIPTION:
            return {
                ...state,
                ...getAssetState(
                    state.vehicles,
                    action.payload,
                ),
            };
        case IS_ASSET_TREE_OPEN:
            return {
                ...state,
                isAssetTreeOpen: action.payload,
            };
        default:
            return state;
    }
};

export default tree;
