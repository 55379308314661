import { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/fp/isEmpty';
import isEqual from 'lodash/fp/isEqual';

import AssetTree from '@rio-cloud/rio-uikit/AssetTree';
import TreeCategory from '@rio-cloud/rio-uikit/TreeCategory';
import Tree from '@rio-cloud/rio-uikit/Tree';

import { FormattedMessage, injectIntl } from 'react-intl';

import {getRouteParamAsArray, saveStateToRoute, getRouteParam} from '../../features/utils/routing';

const getNotShownAssets = (
    selected,
    assets,
) => selected.filter(assetId => !assets.find(asset => asset.id === assetId));

const getNotShownGroups = (
    selected,
    groupIds,
) => selected.filter(groupId => !groupIds.find(id => id === groupId));

class AssetTreeComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentCategoryId: getRouteParam('currentCategoryId') || 'AssetTree-vehicles',
            selectedVehicles: getRouteParamAsArray('selectedVehicles') || [],
            selectedVehicleGroupIds: getRouteParamAsArray('selectedVehicleGroupIds') || [],
            expandedVehicleGroups: getRouteParamAsArray('expandedVehicleGroups') || [],
            selectedDrivers: getRouteParamAsArray('selectedDrivers') || [],
            selectedDriverGroupIds: getRouteParamAsArray('selectedDriverGroupIds') || [],
            expandedDriverGroups: getRouteParamAsArray('expandedDriverGroups') || [],
            tags: {},
        };

        this.handleSelectDriver = this.handleSelectDriver.bind(this);
        this.handleExpandDriverGroups = this.handleExpandDriverGroups.bind(this);

        this.handleVehicleSelection = this.handleVehicleSelection.bind(this);
        this.handleExpandVehicleGroups = this.handleExpandVehicleGroups.bind(this);

        this.onCategoryChange = this.onCategoryChange.bind(this);
        this.onToggleTree = this.onToggleTree.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(
            nextProps,
            this.props,
        ) || !isEqual(
            nextState,
            this.state,
        );
    }

    componentDidMount() {

        if (!isEmpty(this.state.selectedVehicles) || !isEmpty(this.state.selectedVehicleGroupIds)) {
            if (this.props.rawVehicles.length) {
                const notShownSelectedVehicles = getNotShownAssets(
                    this.state.selectedVehicles,
                    this.props.rawVehicles,
                );
                const notShownSelectedVehicleGroups =
                        getNotShownGroups(
                            this.state.selectedVehicleGroupIds,
                            this.props.vehicleGroupIds,
                        );

                this.handleVehicleSelection({
                    items: this.state.selectedVehicles
                        .filter(vehicleId => !notShownSelectedVehicles.includes(vehicleId)),
                    groups: this.state.selectedVehicleGroupIds
                        .filter(groupId => !notShownSelectedVehicleGroups.includes(groupId)),
                });
            }
        }

        if (!isEmpty(this.state.selectedDrivers) || !isEmpty(this.state.selectedDriverGroupIds)) {
            if (this.props.rawDrivers.length) {
                const notShownSelectedDriver =
                    getNotShownAssets(
                        this.state.selectedDrivers,
                        this.props.rawDrivers,
                    );
                const notShownSelectedDriverGroups =
                    getNotShownGroups(
                        this.state.selectedDriverGroupIds,
                        this.props.driverGroupIds,
                    );

                this.handleSelectDriver({
                    items: this.state.selectedDrivers
                        .filter(driverId => !notShownSelectedDriver.includes(driverId)),
                    groups: this.state.selectedDriverGroupIds
                        .filter(groupId => !notShownSelectedDriverGroups.includes(groupId)),
                });

            }
        }

        this.props.setCurrentCategory(this.state.currentCategoryId);
    }

    sendSelectedVehicles(selectedVehicles, selectedVehicleGroupIds) {
        const newselectedVehicles = new Set();
        selectedVehicles.forEach(vehicleId => newselectedVehicles.add(vehicleId));

        selectedVehicleGroupIds.forEach(groupId => {
            if (this.props.vehicleIdsByGroup[groupId]) {
                this.props.vehicleIdsByGroup[groupId].forEach(vehicleId => newselectedVehicles.add(vehicleId));
            }
        });

        saveStateToRoute({
            selectedVehicles: Array.from(newselectedVehicles),
            selectedVehicleGroupIds,
            selectedDrivers: [],
            selectedDriverGroupIds: [],
        });

        this.props.setSelectedVehicles(Array.from(newselectedVehicles));
    }

    handleVehicleSelection({ items, groups }) {

        this.setState({
            selectedVehicles: items,
            selectedVehicleGroupIds: groups,
            selectedDrivers: [],
            selectedDriverGroupIds: [],
        });

        this.sendSelectedVehicles(
            items,
            groups,
        );
    }

    handleExpandVehicleGroups(expandedVehicleGroups) {

        this.setState({
            expandedVehicleGroups,
        });
    }

    handleExpandDriverGroups(expandedDriverGroups) {

        this.setState({
            expandedDriverGroups,
        });
    }

    handleSelectDriver({ items, groups }) {
        const newSelectedDrivers = new Set();
        items.forEach(driverId => newSelectedDrivers.add(driverId));

        groups.forEach(groupId => {
            if (this.props.driverIdsByGroup[groupId]) {
                this.props.driverIdsByGroup[groupId].forEach(driverId => newSelectedDrivers.add(driverId));
            }
        });

        saveStateToRoute({
            selectedVehicles: [],
            selectedVehicleGroupIds: [],
            selectedDrivers: Array.from(newSelectedDrivers),
            selectedDriverGroupIds: groups,
        });

        this.setState({
            selectedVehicles: [],
            selectedVehicleGroupIds: [],
            selectedDrivers: items,
            selectedDriverGroupIds: groups,
        });

        this.props.setSelectedDrivers(Array.from(newSelectedDrivers));
    }

    onToggleTree(isOpen) {
        this.props.setAssetTreeState(isOpen);
    }

    onCategoryChange(newCategory) {
        saveStateToRoute({
            currentCategoryId: newCategory,
        });

        this.props.setCurrentCategory(newCategory);
        this.setState({currentCategoryId: newCategory});
    }

    getVehicleTreeCategory() {
        return (
            <TreeCategory
                key={'AssetTree-vehicles'}
                id={'AssetTree-vehicles'}
                label={<FormattedMessage id={'vehicles'} defaultMessage={'Vehicles'} />}
                icon={'rioglyph-truck'}
                hasSelection={
                    !isEmpty(this.state.selectedVehicles) ||
                    !isEmpty(this.state.selectedVehicleGroupIds)
                }
            >
                <Tree
                    groups={
                        (this.props.vehicleGroupIds || [])
                            .map(id => id === 'unassignedVehicles' ?
                                {
                                    id: 'unassignedVehicles',
                                    name: this.props.intl.formatMessage({
                                        id: 'unassignedVehicles',
                                        defaultMessage: 'All unassigned vehicles',
                                    }),
                                } :
                                this.props.tags[id] || { id, name: id })
                    }
                    items={this.props.vehicles}
                    expandedGroups={this.state.expandedVehicleGroups}
                    onExpandGroupsChange={this.handleExpandVehicleGroups}
                    selectedGroups={this.state.selectedVehicleGroupIds}
                    selectedItems={this.state.selectedVehicles}
                    onSelectionChange={this.handleVehicleSelection}
                    searchPlaceholder={'Enter filter here'}
                />
            </TreeCategory>
        );
    }

    getDriverTreeCategory() {

        return (
            <TreeCategory
                key={'AssetTree-drivers'}
                id={'AssetTree-drivers'}
                label={<FormattedMessage id={'drivers'} defaultMessage={'Drivers'} />}
                icon={'rioglyph-driver'}
                hasSelection={
                    !isEmpty(this.state.selectedDrivers) ||
                    !isEmpty(this.state.selectedDriverGroupIds)
                }
            >
                <Tree
                    items={this.props.drivers}
                    selectedItems={this.state.selectedDrivers}
                    onSelectionChange={this.handleSelectDriver}
                    searchPlaceholder={'Enter filter here'}
                    groups={
                        (this.props.driverGroupIds || [])
                            .map(id => id === 'unassignedDrivers' ?
                                {
                                    id: 'unassignedDrivers',
                                    name: this.props.intl.formatMessage({
                                        id: 'unassignedDrivers',
                                        defaultMessage: 'All unassigned drivers',
                                    }),
                                } :
                                this.props.tags[id] || { id, name: id })
                    }
                    expandedGroups={this.state.expandedDriverGroups}
                    onExpandGroupsChange={this.handleExpandDriverGroups}
                    selectedGroups={this.state.selectedDriverGroupIds}
                />
            </TreeCategory>
        );
    }

    render() {
        return (
            <AssetTree
                resizable
                minWidth={300}
                maxWidth={450}
                isOpen={this.props.isAssetTreeOpen}
                onToggleTree={this.onToggleTree}
                currentCategoryId={this.props.currentCategoryId}
                onCategoryChange={this.onCategoryChange}
            >
                {this.getVehicleTreeCategory()}
                {this.getDriverTreeCategory()}
            </AssetTree>
        );
    }
}

AssetTreeComponent.defaultProps = {
    tags: {},
    vehicleGroupIds: [],
    vehicles: [],
    drivers: [],
    vehicleIdsByGroup: {},
};

AssetTreeComponent.propTypes = {
    intl: PropTypes.object.isRequired,
    tags: PropTypes.object,
    vehicleGroupIds: PropTypes.array,
    vehicles: PropTypes.array,
    rawDrivers: PropTypes.array,
    rawVehicles: PropTypes.array,
    vehicleIdsByGroup: PropTypes.object,
    drivers: PropTypes.array,
    currentCategoryId: PropTypes.string,
    driverGroupIds: PropTypes.array,
    driverIdsByGroup: PropTypes.object,

    successfulFetchAssets: PropTypes.func,
    successfulFetchDrivers: PropTypes.func,
    successfulFetchTags: PropTypes.func,

    setSelectedVehicles: PropTypes.func,
    setSelectedDrivers: PropTypes.func,

    setCurrentCategory: PropTypes.func,
    isAssetTreeOpen: PropTypes.bool,
    setAssetTreeState: PropTypes.func,
};

export default injectIntl(AssetTreeComponent);
