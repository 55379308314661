
import { connect } from 'react-redux';
import get from 'lodash/get';

import { AREPERMISSIONSHIDDEN } from '../../constants/archive';
import ForbiddenPage from '../../features/DefaultPages/ForbiddenPage';
import withPermissions from '../../utils/withPermissions';

import {
    setSuccessfulRequestDownload,
    setEventOnManualDownload,
} from '../../actions/administration';

import getListOfRequests from '../../fetches/ManualDownload/getListOfRequests';
import getListOfVehicles from '../../fetches/ManualDownload/getListOfVehicles';
import requestDownload from '../../fetches/ManualDownload/requestDownload';

// import ManualDownloadPageWithGroups from './ManualDownloadPageWithGroups';
import cancelDownload from '../../fetches/ManualDownload/cancelDownload';

import { env } from '../../env';

import ManualDownloadPageWithGroupsAndCancel from './ManualDownloadPageWithGroupsAndCancel';
import ManualDownloadPageWithGroups from './ManualDownloadPageWithGroups';

const { isRunningOnProd } = env;

export const ManualDownloadContainer = props => {

    const component = isRunningOnProd ?
        <ManualDownloadPageWithGroups {...props}/> :
        <ManualDownloadPageWithGroupsAndCancel {...props}/>;

    const renderComponentwithPermission = () => withPermissions(
        props.permissions,
        'manual-download.perform',
        component,
    );

    return withPermissions(
        props.permissions,
        AREPERMISSIONSHIDDEN ? '' : 'file-list.read',
        renderComponentwithPermission(),
        <ForbiddenPage/>,
    );
};

export const mapStateToProps = state => ({
    permissions: get(
        state,
        'tachographservices.common.permissions',
        [],
    ),
    vehicles: get(
        state,
        'tachographservices.tree.vehicles',
        [],
    ),
    drivers: get(
        state,
        'tachographservices.tree.drivers',
        [],
    ),
    listOfVehicles: get(
        state,
        'tachographservices.administration.listOfVehicles',
        [],
    ),
    listOfVehiclesLoading: get(
        state,
        'tachographservices.administration.listOfVehiclesLoading',
        [],
    ),
    listOfRequests: get(
        state,
        'tachographservices.administration.listOfRequests',
        [],
    ),
    successfulRequestDownload: get(
        state,
        'tachographservices.administration.successfulRequestDownload',
    ),
    tags: state.tachographservices.tree.tags,
    vehicleGroupIds: state.tachographservices.tree.vehicleGroupIds,
    vehicleIdsByGroup: state.tachographservices.tree.vehicleIdsByGroup,
});

export const mapDispatchToProps = dispatch => ({

    fetchLists: () => {
        getListOfVehicles(dispatch);
        getListOfRequests(dispatch);
    },

    requestDownload: payload => {
        dispatch(setEventOnManualDownload());
        requestDownload(
            dispatch,
            payload,
        );
    },

    cancelDownload: payload => {
        dispatch(setEventOnManualDownload());
        cancelDownload(
            dispatch,
            payload,
        );
    },

    setSuccessfulRequestDownload: payload => {
        dispatch(setSuccessfulRequestDownload(payload));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ManualDownloadContainer);
