/* eslint-disable default-param-last */
import { Component } from 'react';
import PropTypes from 'prop-types';
import {FormattedDate, FormattedMessage, injectIntl} from 'react-intl';
import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import ClearableInput from '@rio-cloud/rio-uikit/ClearableInput';
import { AutoSuggest } from '@rio-cloud/rio-uikit/AutoSuggest';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';

import isEqual from 'lodash/fp/isEqual';
import cloneDeep from 'lodash/fp/cloneDeep';
import sortBy from 'lodash/sortBy';
import Fuse from 'fuse.js';

import {dateProps} from '../../constants/common';
import TooltipTrigger from '../../components/common/TooltipTrigger';

import { getDiffDays } from '../Overview/helpers';

import {sortVehicles} from './helpers';
import CompanyCardReturnForm from './CompanyCardReturnForm';

const getAssignedVehicleRow = (vehicle = {}, onDelete) => (
    <div key={`AssignedVehicleRow-${vehicle.id}`} className={'list-group-item display-flex align-items-center'}>
        <div className={'display-flex justify-content-between align-items-center flex-wrap width-100pct'}>
            <div>
                <span>
                    { vehicle.name || vehicle.id }
                </span>
            </div>
            <div className={'btn btn-link'} onClick={onDelete}>
                <span className={'rioglyph rioglyph-remove'}/>
            </div>
        </div>
    </div>
);

const getExpireDate = companycard => {
    if (companycard.expirationDate) {
        const expireDate = new Date(companycard.expirationDate);
        if (getDiffDays(expireDate) > 0) {
            return <div className={'col-md-6 text-color-danger'}>
                {
                    <FormattedDate value={companycard.expirationDate} {...dateProps}/>
                }
                <span className={'margin-left-5'} >
                    {
                        <FormattedMessage
                            id={'administration.expiredDateSoon'}
                            defaultMessage={'expired'}
                        />
                    }
                </span>
            </div>;
            // eslint-disable-next-line no-magic-numbers
        } else if (getDiffDays(expireDate) > -90) {
            return <div className={'col-md-6 text-color-warning'}>
                {
                    <FormattedDate value={companycard.expirationDate} {...dateProps}/>
                }
                <span className={'margin-left-5'} >
                    {
                        <FormattedMessage
                            id={'administration.expiredDateSoon'}
                            defaultMessage={'expires soon'}
                        />
                    }
                </span>
            </div>;
        }
        return <div className={'col-md-6 text-color-black'}>
            {
                <FormattedDate value={companycard.expirationDate} {...dateProps}/>
            }
        </div>;
    }
    return '';
};

const COMPANY_CARD_UNAVAILABLE = 0;

class CompanyCardsSidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            companyCard: this.props.companyCard,
            assignCardsOpen: false,
            search: '',
            confirmDeleteOpen: false,
            confirmChangesOpen: false,
            confirmRestoreClicked: false,
        };

        this.closeSidebar = this.closeSidebar.bind(this);
        this.toggleAssignVehiclesOpen = this.toggleAssignVehiclesOpen.bind(this);
        this.setSearch = this.setSearch.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.handleVehicleSelectChange = this.handleVehicleSelectChange.bind(this);
        this.closeConfirmDeletion = this.closeConfirmDeletion.bind(this);
        this.handleReturnFormCallback = this.handleReturnFormCallback.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(
            prevProps.companyCard,
            this.props.companyCard,
        )) {
            this.setState({
                companyCard: this.props.companyCard,
            });
        }
    }

    closeSidebar() {
        this.props.setCompanyCardsSidebarOpen(false);
    }

    // eslint-disable-next-line complexity
    getCompanyCardsForm() { // eslint-disable-line max-lines-per-function
        return (
            <div className={'form-group'}>
                <div className={'form-group'}>
                    <div className={'row'}>

                        <div className={'col-md-6'}>
                            <label className={'margin-bottom-0'}>
                                <FormattedMessage
                                    id={'administration.companyCardNumber'}
                                    defaultMessage={'Company card number'}
                                />
                                <TooltipTrigger
                                    tooltip={
                                        <FormattedMessage
                                            id={'administration.companyCardNumberTooltip'}
                                            defaultMessage={
                                                'This is the number written on the company card.'
                                            }
                                        />
                                    }
                                    baseKey={'companyCardNumberTooltip'}
                                >
                                    <span className={'rioglyph rioglyph-info-sign text-primary margin-left-5'}/>
                                </TooltipTrigger>
                            </label>
                        </div>
                        <div className={'col-md-6'}>
                            <label className={'margin-bottom-0'}>
                                <FormattedMessage
                                    id={'administration.companyCardId'}
                                    defaultMessage={'Company card ID'}
                                />
                                <TooltipTrigger
                                    tooltip={
                                        <FormattedMessage
                                            id={'administration.companyCardIdTooltip'}
                                            defaultMessage={
                                                'This is the chip identification of the company card.'
                                            }
                                        />
                                    }
                                    baseKey={'companyCardIdTooltip'}
                                >
                                    <span className={'rioglyph rioglyph-info-sign text-primary margin-left-5'}/>
                                </TooltipTrigger>
                            </label>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-md-6'}>
                            {this.state.companyCard.companyCardNumber || ''}
                        </div>
                        <div className={'col-md-6'}>
                            {this.state.companyCard.companyChipId || ''}
                        </div>
                    </div>
                </div>
                <div className={'form-group'}>
                    <div className={'row'}>
                        <div className={'col-md-6'}>
                            <label className={'margin-bottom-0'}>
                                <FormattedMessage
                                    id={'administration.companyName'}
                                    defaultMessage={'Company name'}
                                />
                                <TooltipTrigger
                                    tooltip={
                                        <FormattedMessage
                                            id={'administration.companyNameTooltip'}
                                            defaultMessage={
                                                'This is the company name saved on the company card.'
                                            }
                                        />
                                    }
                                    baseKey={'companyNameTooltip'}
                                >
                                    <span className={'rioglyph rioglyph-info-sign text-primary margin-left-5'}/>
                                </TooltipTrigger>
                            </label>
                        </div>
                        <div className={'col-md-6'}>
                            <label className={'margin-bottom-0'}>
                                <FormattedMessage
                                    id={'administration.expiryDate'}
                                    defaultMessage={'Expiry Date'}
                                />
                                <TooltipTrigger
                                    tooltip={
                                        <FormattedMessage
                                            id={'administration.expiryDateTooltip'}
                                            defaultMessage={
                                                'This is the expiration date of the company card. ' +
                                                'It can differ from what is shown in the application.'
                                            }
                                        />
                                    }
                                    baseKey={'expiryDateTooltip'}
                                >
                                    <span className={'rioglyph rioglyph-info-sign text-primary margin-left-5'}/>
                                </TooltipTrigger>
                            </label>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-md-6'}>
                            {this.state.companyCard.companyName || ''}
                        </div>
                        {getExpireDate(this.state.companyCard)}
                    </div>
                </div>
                {<div className={'form-group'}>
                    <div className={'row'}>
                        <div className={'col-md-6'}>
                            <label className={'margin-bottom-0'}>
                                <FormattedMessage
                                    id={'administration.cardType'}
                                    defaultMessage={'Connection type'}
                                />
                                <TooltipTrigger
                                    tooltip={
                                        <FormattedMessage
                                            id={'administration.companytypeTooltip'}
                                            defaultMessage={
                                                'This is the company card connection type'
                                            }
                                        />
                                    }
                                    baseKey={'companytypeTooltip'}
                                >
                                    <span className={'rioglyph rioglyph-info-sign text-primary margin-left-5'}/>
                                </TooltipTrigger>
                            </label>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-md-6'}>
                            <span
                                className={`padding-right-10
                                 rioglyph ${this.props.companyCard.inCardHotel ?
                'rioglyph-card-hotel' :
                'rioglyph-card-reader'}`}/>
                            {this.props.companyCard.inCardHotel ? <span>Card Hotel</span> : <span>RDA</span>}
                        </div>
                    </div>
                </div>}
                <div className={'form-group'}>
                    <label>
                        <FormattedMessage
                            id={'administration.companyCardName'}
                            defaultMessage={'Company card name'}
                        />
                    </label>
                    <br/>
                    <ClearableInput
                        value={this.state.companyCard.companyCardName || ''}
                        onChange={
                            value => {
                                const newCompanyCard = cloneDeep(this.state.companyCard);
                                newCompanyCard.companyCardName = value;

                                this.setState({
                                    companyCard: newCompanyCard,
                                });
                            }
                        }
                        autoFocus={this.state.companyCard.editName}
                    />
                </div>
            </div>
        );
    }

    toggleAssignVehiclesOpen() {
        this.setState({
            assignCardsOpen: !this.state.assignCardsOpen,
        });
    }

    getVehicleOptions() {
        // eslint-disable-next-line immutable/no-let
        let vehicles = this.props.tbm3Vehicles;
        if (this.props.companyCard.inCardHotel) {
            vehicles = vehicles
                .filter(vehicleId => this.props.vehiclesWithSubscription
                    .find(vehicleWithSubscription => vehicleId === vehicleWithSubscription.assetId &&
                !vehicleWithSubscription.currentLevel.startsWith('10')));
        }
        return sortBy(
            vehicles
                .filter(vehicleId => !this.state.companyCard.assignedEquipmentIds.includes(vehicleId))
                .map(vehicleId => this.props.vehicles.find(vehicle => vehicle.id === vehicleId) ||
                    {
                        id: vehicleId,
                        name: vehicleId,
                    }),
            ['name'],
        );
    }

    getSuggestions() {
        const vehicleOptions = this.getVehicleOptions();
        const { search } = this.state;
        const options = {
            location: 0,
            threshold: 0.5,
            distance: 100,
            minMatchCharLength: 1,
            keys: ['name'],
        };
        const fuse = new Fuse(
            vehicleOptions,
            options,
        );

        return !search || search === '' ? vehicleOptions : fuse.search(search);
    }

    onSuggestionsFetchRequested({ value }) {
        this.setState({
            search: value,
        });
    }

    onSuggestionsClearRequested() {
        this.setState({
            search: '',
        });
    }

    handleVehicleSelectChange(event, { suggestion }) {
        const newCompanyCard = cloneDeep(this.state.companyCard);
        const newRemovedVehicles = newCompanyCard.deletedEquipmentIds || [];

        if (!newCompanyCard.assignedEquipmentIds) {
            newCompanyCard.assignedEquipmentIds = [];
        }
        newCompanyCard.assignedEquipmentIds.push(suggestion.id);

        newCompanyCard.deletedEquipmentIds = newRemovedVehicles.filter(vehicleId => vehicleId !== suggestion.id);

        this.setState({
            companyCard: newCompanyCard,
        });
    }

    setSearch(event) {
        this.setState({
            search: event.target.value,
        });
    }

    clearSearch() {
        this.setState({
            search: '',
        });
    }

    getAssignVehiclesAutoSuggest() {
        const inputProps = {
            icon: 'rioglyph-search',
            value: this.state.search,
            className: '',
            onChange: this.setSearch,
            onClear: this.clearSearch,
            placeholder: this.props.intl.formatMessage({ id: 'pleaseSelect' }),
        };

        return (
            <div className={'padding-bottom-10'}>
                <AutoSuggest
                    suggestions={this.getSuggestions()}
                    inputProps={inputProps}
                    noItemMessage={
                        <FormattedMessage
                            id={'noVehicles'}
                            defaultMessage={'There are no vehicles to display'}
                        />
                    }
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    onSuggestionSelected={this.handleVehicleSelectChange}
                    renderSuggestion={suggestion => suggestion.name}
                    getSuggestionValue={suggestion => suggestion.name}
                    openOnFocus
                />
            </div>
        );
    }

    getAssignedVehiclesForm() {
        return (
            <div className={'form-group'}>
                <div className={'display-flex justify-content-between align-items-center margin-bottom-10'}>
                    <span className={'font-weight-800'}>
                        <FormattedMessage
                            id={'administration.assignedVehicles'}
                            defaultMessage={'Assigned vehicles'}
                        />
                    </span>
                    {
                        this.state.assignCardsOpen ?
                            <div
                                className={'btn btn-default btn-sm'}
                                onClick={this.toggleAssignVehiclesOpen}
                            >
                                <FormattedMessage
                                    id={'close'}
                                    defaultMessage={'Close'}
                                />
                            </div> :
                            <div
                                className={'btn btn-primary btn-sm'}
                                onClick={this.toggleAssignVehiclesOpen}
                            >
                                <span className={'rioglyph rioglyph-plus'}/>
                                <FormattedMessage
                                    id={'administration.addVehicles'}
                                    defaultMessage={'Add vehicles'}
                                />
                            </div>
                    }
                </div>
                { this.state.assignCardsOpen && this.getAssignVehiclesAutoSuggest() }
                <div className={'list-group border rounded'}>
                    {
                        this.getAssignedVehiclesSorted()
                    }
                </div>
            </div>
        );
    }

    getAssignedVehiclesSorted() {
        return sortVehicles(
            this.props.vehicles,
            this.state.companyCard.assignedEquipmentIds || [],
        ).map(vehicleId => getAssignedVehicleRow(
            this.props.vehicles.find(vehicle => vehicle.id === vehicleId) || { id: vehicleId },
            () => {
                const newCompanyCard = cloneDeep(this.state.companyCard);
                const newRemovedIds = newCompanyCard.deletedEquipmentIds || [];

                newCompanyCard.deletedEquipmentIds = newRemovedIds.concat(vehicleId);
                newCompanyCard.assignedEquipmentIds =
                    newCompanyCard.assignedEquipmentIds.filter(id => id !== vehicleId);

                this.setState({
                    companyCard: newCompanyCard,
                });
            },
        ));
    }

    getFooter() {
        const expireDate = new Date(this.props.companyCard.expirationDate);
        if (this.props.companyCard.inCardHotel && getDiffDays(expireDate) < 0) {
            return <div className={'display-flex justify-content-between align-items-center'}>
                {
                    this.props.companyCard.returnRequestSent ?
                        <div
                            className={'btn btn-link btn-danger opacity-50'}
                            onClick={() => {}}
                        >
                            <span className={'rioglyph rioglyph-envelope'}/>
                            <span
                                className={'margin-left-5'}
                                style={{whiteSpace: 'normal', minWidth: 130}}>
                                <FormattedMessage
                                    id={'administration.companyCardReturnRequested'}
                                    defaultMessage={'Card return requested'}
                                />
                            </span>
                        </div> :
                        <div
                            className={'btn btn-link btn-danger'}
                            onClick={() => this.setState({ confirmDeleteOpen: true })}
                            style={{whiteSpace: 'normal', minWidth: 130}}
                        >
                            <span className={'rioglyph rioglyph-envelope'}/>
                            <span className={'margin-left-5'} style={{whiteSpace: 'normal', minWidth: 130}}>
                                <FormattedMessage
                                    id={'administration.restoreCompanyCard'}
                                    defaultMessage={'Request card return'}
                                />
                            </span>
                        </div>
                }
                <div className={'btn-toolbar'} style={{flexWrap: 'nowrap'}}>
                    <a
                        className={'btn btn-default padding-5'}
                        onClick={this.closeSidebar}
                    >
                        <FormattedMessage id={'cancel'} defaultMessage={'Cancel'} />
                    </a>
                    <a
                        className={'btn btn-primary padding-5'}
                        onClick={() => this.setState({ confirmChangesOpen: true })}
                    >
                        <FormattedMessage id={'save'} defaultMessage={'Save'} />
                    </a>
                </div>
            </div>;
        }
        return (
            <div className={'display-flex justify-content-between align-items-center'}>
                {
                    this.props.companyCard.status === COMPANY_CARD_UNAVAILABLE || !this.props.isRDAOnline ?

                        <div
                            className={'btn btn-link btn-danger'}
                            onClick={() => this.setState({ confirmDeleteOpen: true })}
                        >
                            <span className={'rioglyph rioglyph-trash'}/>
                            <span className={'margin-left-5'} style={{whiteSpace: 'normal'}}>
                                <FormattedMessage
                                    id={'administration.deleteCompanyCard'}
                                    defaultMessage={'Delete company card'}
                                />
                            </span>
                        </div> :
                        <TooltipTrigger
                            tooltip={
                                <FormattedMessage
                                    id={'administration.companyCardHasToBeDisconnectedForDeletion'}
                                    defaultMessage={
                                        'Please disconnect the company card before deletion.'
                                    }
                                />
                            }
                            baseKey={'removeBeforeDeletionMessage'}
                        >
                            <div
                                className={'btn btn-link btn-danger opacity-50'}
                                onClick={() => {}}
                            >
                                <span className={'rioglyph rioglyph-trash'}/>
                                <span
                                    className={'margin-left-5'}
                                    style={{whiteSpace: 'normal'}}
                                >
                                    <FormattedMessage
                                        id={'administration.deleteCompanyCard'}
                                        defaultMessage={'Delete company card'}
                                    />
                                </span>
                            </div>
                        </TooltipTrigger>
                }
                <div className={'btn-toolbar'} style={{flexWrap: 'nowrap'}}>
                    <a
                        className={'btn btn-default padding-5'}
                        onClick={this.closeSidebar}
                    >
                        <FormattedMessage id={'cancel'} defaultMessage={'Cancel'} />
                    </a>
                    <a
                        className={'btn btn-primary padding-5'}
                        onClick={() => this.setState({ confirmChangesOpen: true })}
                    >
                        <FormattedMessage id={'save'} defaultMessage={'Save'} />
                    </a>
                </div>
            </div>
        );

    }

    render() { // eslint-disable-line max-lines-per-function
        return (
            <Sidebar
                title={
                    <span>
                        <FormattedMessage
                            id={'companyCard'}
                            defaultMessage={'Company Card'}
                        />
                        <span className={'margin-left-5'}>
                            { this.state.companyCard.companyName || this.state.companyCard.companyCardNumber || '' }
                        </span>
                    </span>
                }
                maxWidth={500}
                titleClassName={'padding-left-10'}
                bodyClassName={'padding-20'}
                closed={!this.props.isCompanyCardsSidebarOpen}
                onClose={this.closeSidebar}
                footer={this.getFooter()}
            >
                { this.getCompanyCardsForm() }
                { this.getAssignedVehiclesForm() }
                { this.getConfirmDelete() }
                { this.getConfirmChanges() }
            </Sidebar>
        );
    }

    getConfirmDelete() {
        const expireDate = new Date(this.props.companyCard.expirationDate);
        const form = <CompanyCardReturnForm
            {...this.props}
            confirmRestoreClicked = {this.state.confirmRestoreClicked}
            closeConfirmDeletion = {this.closeConfirmDeletion}
            closeSidebar = {this.closeSidebar}
            handleReturnFormCallback = {this.handleReturnFormCallback}
            companyChipId = {this.state.companyCard.companyChipId} />;
        const content = this.props.companyCard.inCardHotel && getDiffDays(expireDate) < 0 ?
            <div>
                {form}
            </div> :
            <p>
                <FormattedMessage
                    id={'administration.confirmDeletionMessage'}
                    defaultMessage={'Are You sure that you want to delete this company card?'}
                />
            </p>;
        return (
            <ConfirmationDialog
                show={this.state.confirmDeleteOpen}
                onClickConfirm={() => {
                    if (this.props.companyCard.inCardHotel && getDiffDays(expireDate) < 0) {
                        // eslint-disable-next-line no-warning-comments
                        // TODO use redux
                        this.setState({confirmRestoreClicked: true});
                        setTimeout(
                            () => {
                                this.setState({confirmRestoreClicked: false});
                            },
                            // eslint-disable-next-line no-magic-numbers
                            1000,
                        );
                    } else {
                        this.setState({ confirmDeleteOpen: false });
                        this.props.sendDeletion(this.state.companyCard);
                        this.closeSidebar();
                    }
                }}
                onClickCancel={() => {
                    this.setState({ confirmDeleteOpen: false });
                }}
                cancelButtonText={<FormattedMessage id={'cancel'}/>}
                confirmButtonText={<FormattedMessage id={'confirm'}/>}
                title={<FormattedMessage id={'confirmRequest'} defaultMessage={'Confirm Request'}/>}
                content={content}
            />
        );
    }

    handleReturnFormCallback(form) {
        this.setState({restoredCard: form});
        this.props.sendRestoration(this.state.restoredCard);
    }

    closeConfirmDeletion() {
        this.setState({ confirmDeleteOpen: false });
    }

    getConfirmChanges() {
        return (
            <ConfirmationDialog
                show={this.state.confirmChangesOpen}
                onClickConfirm={() => {
                    this.setState({ confirmChangesOpen: false });
                    this.props.sendChanges(this.state.companyCard);
                    this.closeSidebar();
                }}
                onClickCancel={() => {
                    this.setState({ confirmChangesOpen: false });
                }}
                cancelButtonText={<FormattedMessage id={'cancel'}/>}
                confirmButtonText={<FormattedMessage id={'confirm'}/>}
                title={<FormattedMessage id={'confirmChanges'} defaultMessage={'Confirm Changes'}/>}
                content={
                    <p>
                        <FormattedMessage
                            id={'confirmChangesMessage'}
                            defaultMessage={'Are You sure that you want to save these changes?'}
                        />
                    </p>
                }
            />
        );
    }
}

CompanyCardsSidebar.defaultProps = {
    tbm3Vehicles: [],
    vehicles: [],
    companyCard: {
        assignedEquipmentIds: [],
    },
    confirmRestoreClicked: false,
    isCompanyCardsSidebarOpen: false,
    setCompanyCardsSidebarOpen: () => {},
    sendChanges: () => {},
    sendDeletion: () => {},
    sendRestoration: () => {},
    accountInfo: {},
};

CompanyCardsSidebar.propTypes = {
    tbm3Vehicles: PropTypes.array,
    vehicles: PropTypes.array,
    companyCard: PropTypes.object,
    intl: PropTypes.object.isRequired,
    locale: PropTypes.string,

    isCompanyCardsSidebarOpen: PropTypes.bool,
    setCompanyCardsSidebarOpen: PropTypes.func,
    sendChanges: PropTypes.func,
    sendDeletion: PropTypes.func,
    sendRestoration: PropTypes.func,
    accessToken: PropTypes.string.isRequired,
    vehiclesWithSubscription: PropTypes.array,
    confirmRestoreClicked: PropTypes.bool,
    email: PropTypes.string,
    name: PropTypes.string,
    isRDAOnline: PropTypes.bool,
    accountInfo: PropTypes.object,
};

export default injectIntl(CompanyCardsSidebar);
