export const productionConfig = {
    homeRoute: 'https://home.rio.cloud',
    backend: {
        AUTHENTICATION_SERVICE: 'https://auth.iam.rio.cloud/oauth',
        TACHOGRAPH_SERVICES_SERVICE: 'https://api.remotedownload.rio.cloud',
        RETENTION_PATH: `https://api.remotedownload.rio.cloud/dataremover/retention-periods/fleet`,
        TACHO_SETTINGS_PATH: `https://api.remotedownload.rio.cloud/settings`,
        VEHICLES_WITH_SUBSCRIPTION_PATH: `https://api.remotedownload.rio.cloud/downloadscheduler/vehicles-with-subscription`,
        ANALYSIS_PORTAL: 'https://man.pre.tachoweb.eu'
    },
    id: 'env.production',
    login: {
        authority: `https://auth.iam.rio.cloud`,
        mockAuthorization: false,
        uri: 'https://auth.iam.rio.cloud/login',
        clientId: 'b8a414b3-6f71-453e-b53b-6c02a2925004',
        callbackUri: `${window.location.origin}`,
        redirectUri: `${window.location.origin}/redirect.html`,
        silentRedirectUri: `${window.location.origin}/redirect.html`,
        preventRedirect: false,
    },
};
